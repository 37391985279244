import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import style from './Common.module.scss';
function DateRangePickers(props) {
    //customselect options
    const options ={
        All : [moment().year('2020').startOf('year'), moment().toDate()],
        Today: [moment().toDate(), moment().toDate()],
        'This Week': [
        moment().startOf('week'),
        moment().endOf('week'),
        ],
        'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month'),
        ],
        'This Year': [
        moment().startOf('year').toDate(),
        moment().endOf('year'),
        ],
    }
    return (
        <DateRangePicker
            initialSettings={{
                startDate: props.start.toDate(),
                endDate: props.end.toDate(),
                ranges: options ,
            }}
            onCallback={props.change}
            >
              
            <div
            className={style.DateRangePicker}
            >
                <i className="fa fa-calendar"></i>&nbsp;
                <span>{props.label}</span> <i className="fa fa-caret-down"></i>
            </div>
        </DateRangePicker>
    )
}

export default DateRangePickers
