import React from 'react';
import {Modal}from 'react-bootstrap';
//import style from './crm.module.scss';
import style from '../crm/crm.module.scss'
const DashboardModel = ({children,...props}) => {
    return ( 
        <Modal show={props.show}>
            <Modal.Header  className={`${style.ModalHeader}`}>
            <Modal.Title className={`text-center`}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`p-0`}>
                {children}
            </Modal.Body>
        </Modal>
     );
}
 
export default DashboardModel;