import React from 'react';
import style from './party.module.scss';
import {StepFive,StepThree,StepFour,StepOne,StepTwo} from './';
function SetupContent(props) {
    return (
        <div className={`${style.container}`}>
            <StepOne
                {...props}
            />
            <StepTwo
                {...props}
            />
            <StepThree
                {...props}
            />
            <StepFour
                {...props}
            />
            <StepFive
                {...props}
            />
        </div>
    )
}

export default SetupContent
