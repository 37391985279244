import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation }  from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router';
import {StepContent} from '../../components/network/step';
import StepService from '../../../service/tree/step';
import companyName from '../../../store/mobxStore/companyName';



const StepView = (props) => {

                   const [ state , setState]  = useState({
                            loading : false,
                            users: [],
                            tree_depth: null,
                            tree_width: null
                        })
                        
                      const  service = new StepService();


useEffect(()=>{

    service.getStepUsers().then( res => {
        if(res.status) {
            setState(prevState => ({
                ...prevState,
                users: res.data.users
            }))
        } else {
            if(res.error.code ===1002) {
                props.history.push('/logout')
            }
        }
    })
},[])


    const { t } = props;
    return (
    <div>
        {state.loading &&
            <Spinner />
        }

        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.stairStepView')}</title>
            </Helmet>
            <PageTitle title="stairStepView" buttonOn={false} />
            <StepContent
            {...state}
            />
        </div>
        </div>
    );


}

export default  withTranslation()((withRouter(StepView)))