import { borderRadius } from '@mui/system';
import React from 'react';
import {Modal} from 'react-bootstrap'
import style from './registration.module.scss';




function TAndC(props) {
    return (
        <Modal 
            show={props.data.show} 
            onHide={props.handleClose}
            contentClassName={style.ModalContent}
            >
           
            <Modal.Header closeButton className={style.termsModalheader}  >
                <Modal.Title >Terms and conditions</Modal.Title>
            </Modal.Header>
        
            <Modal.Body dangerouslySetInnerHTML={{__html:props.data.content}}></Modal.Body>
       
      </Modal>
    )
}

export default TAndC
