import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class StepService {
    key =localStorage.getItem('apiKey')
    //get step users
    async getStepUsers() {
        const response = await fetch(`${BASE_URL}tree/step_view`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}