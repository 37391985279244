import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
//components
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner'
import CrmAddLead from '../../components/crm/CrmAddLead';

//service
//import CrmService from '../../../service/crm/Crm';
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';






const AddLead = ({ t}) => {


    const [state, setState] = useState({
        loading: false,
        data: [],
        country: [],
        modal: {
            show: false,
            header: ''
        }

    })
    const handleClose = () => {
        setState(prevState => ({
            ...prevState,
            modal: {
                show: false,
                header: ''
            }
        }));

    }
    //show modal
    const showModal = () => {
        setState(prevState => ({
            ...prevState,
            modal: {
                show: true
            }
        }));
    }
    if (state.loading) {
        return <Spinner />
    }
    else {
        return (
            <>
                <div className="h-100">
                    <Helmet>
                        <title>{companyName.companyName} | {t('Sidemenu.addLead')}</title>
                    </Helmet>
                    <PageTitle title="addLead" buttonOn={false} />
                    <CrmAddLead
                        {...state}
                        handleClose={handleClose}
                        showModal={showModal}
                    />
                </div>
            </>
        )
    }

}
export default withTranslation()((withRouter(AddLead)));