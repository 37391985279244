import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './profile.module.scss';

function SuccessModal(props) {
    const {t} = useTranslation()
    return (
        <Modal 
            show={props.show}
            backdropClassName={`modalBackDrops`}
            contentClassName={style.ModalContent}
            onHide={()=>{}}
            dialogClassName={`animate__animated ${props.show?'animate__fadeInDown':'animate__fadeInUp'}`}
            >
            <Modal.Header className={style.ModalHeader}>
                <h3>
                    <i className={`fa fa-check fa-3x`}></i>
                </h3>
                <Modal.Title className={style.ModalTitle}>{t('Common.success')}</Modal.Title>
                <p>{t('profile.passwordUpdatedSuccessfully')}</p>
            </Modal.Header>
            <Modal.Body className={style.ModalBody}>
                <div className={style.SuccessModalButtonClose}>
                    <Button onClick={props.closeModal}>
                        {t('Button.close')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModal
