import React, { useState , useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import SupportContent from "../../components/support/SupportContent";
import Spinner from "../../shared/Spinner";
import ticketService from "../../../service/ticket/ticket";
import { withRouter } from "react-router-dom";
import companyName from "../../../store/mobxStore/companyName";
import AlertMessage from '../../components/common/AlertMessage';


function Support (props) {
    const [state,setState] = useState({
      loader: true,
      localloading:false,
      resetloading:false,
      submitloader:false,


      filters: {
        categories: [],
        priorities: [],
        statuses: [],
      },

      ticket_filters: {
        category: "",
        priority: "",
        status: "",
        ticket_id: "",
      },

      create_ticket: {
        subject: "",
        priority: "",
        category: "",
        message_to_admin: "",
        attachment: "",
      },
      FormError: {
        subject: {
          error: "",
        },
        priority: {
          error: "",
        },
        category: {
          error: "",
        },
        message_to_admin: {
          error: "",
        },
        attachment: {
          error: "",
        },
      },
      tickets: [],
      faqs: [],
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    });


    const notifyInintialState = {
      show : false,
      message : '',
      type : '',
      header : ''
  }
    const [notify,setNotify] = useState(notifyInintialState)


    useEffect(() => {

      getTicketFormFilters();
      getTickets();
      const timer = setTimeout(() => {
        setState((prevState) => ({ 
          ...prevState,
          loader:false
  
        }));
      }, 1000);
      return () => clearTimeout(timer);

    
    }, []);
    useEffect(() => {
    
      getTicketFormFilters();
    }, [state.tickets]);


    

    const service = new ticketService();

  

  const getTickets = (category=state.ticket_filters.category,priority=state.ticket_filters.priority,status=state.ticket_filters.status,ticket_id=state.ticket_filters.ticket_id,loader) => {
    setState((prevState) => ({ 
      ...prevState,
      [loader]:true
  
    }));
  let  params ={
    category: category,
    priority: priority,
    status:status,
    ticket_id:ticket_id

   } 
    service.getTickets(params).then((res) => {
      if (res.status) {
        setState((prevState) => ({ 
          ...prevState,
          tickets: res.data.tickets,
          faqs: res.data.faqs,
        }));
        setTimeout(() => {
          setState((prevState) => ({ 
            ...prevState,
            [loader]:false
          }));
        }, 1000);
      }
    });
  };

  const getTicketFormFilters = () => {
    service.getTicketFormFilters().then((res) => {
      if (res.status) {
        setState((prevState) => ({ 
          ...prevState,
          filters: res.data.filters,
        }));
      }
    });
  };

  const resetTicketFilter = () => {
    setState((prevState) => ({
      ...prevState,
      ticket_filters: {
        category: "",
        priority: "",
        status: "",
        ticket_id: "",
      },
    }))
    getTickets('','','','','resetloading')
  }


 const searchTickets = ()=>{
    getTickets(state.ticket_filters.category,state.ticket_filters.priority,state.ticket_filters.status,state.ticket_filters.ticket_id,'localloading')

  }

  const resetTicketForm = () => {
    setState((prevState) => ({
      ...prevState,
      create_ticket: {
        subject: "",
        priority: "",
        category: "",
        message_to_admin: "",
        attachment: "",
      },
    }));
  };

  const notifyDissmissed = () =>{
    setNotify(prev=>({
        ...prev,
        show : false
    }))
}

  const showNotify = (type,header,message) =>{
    setNotify(prev=>({
        ...prev,
        show : true,
        type : type,
        header : t('Common.'+header),
        message : t(message)
    }))
}
  


  const changeTicketFilter = (type, e) => {
    setState((prevState) => ({
      ...prevState,
      ticket_filters: {
        ...state.ticket_filters,
        [type]: e.target.value,
      },
    }));
  };

  const changeCreateTicket = (e) => {
    let { name, value } = e.target;
    if (name === "attachment") {
      // file input
      if (e.target.files[0]) {
        value = e.target.files[0];
      }
    }
    setState((prevState) => ({
      ...prevState,
      create_ticket: {
        ...prevState.create_ticket,
        [name]: value,
      },
      FormError: {
        ...prevState.FormError,
        [name]: {
          error: "",
        },
      },
    }));

  };

  const createTicket = (e) => {

    e.preventDefault();
 
    let formData = new FormData();
    formData.append("subject", state.create_ticket.subject);
    formData.append("priority", state.create_ticket.priority);
    formData.append("category", state.create_ticket.category);
    formData.append(
      "message_to_admin",
      state.create_ticket.message_to_admin
    );
    formData.append("attachment", state.create_ticket.attachment);
    setState((prevState) => ({
      ...prevState,
     submitloader:true
   }));

    service.createTicket(formData).then((res) => {
      if (res.status) {
        getTickets();

        setState((prevState) => ({
           ...prevState,
          alert: {
            show: true,
            message: "validation.ticketCreatedSuccessfully",
            varient: "success",
          },
          create_ticket: {
            ...prevState.create_ticket,
            subject: "",
            priority: "",
            category: "",
            message_to_admin: "",
            attachment: "",
          },
          filters: {
            ...prevState.filters,
            categories: [],
            priorities: [],
            statuses: [],
          },
          submitloader:false,
        }));


        e.target.reset();
        window.scrollTo(0, 0);
      } else {
        setState((prevState) => ({
          ...prevState,
         submitloader:false
       }));
        if (res.error.code === 1004) {       
          const { FormError } = state;
          for (const property in res.error.fields) {
            if (FormError.hasOwnProperty(property)) {
              FormError[property] = {              
                error: `validation.${property}`,                
                field: property,
               
              };
          
            }
          }
          setState((prevState) => ({ 
            ...prevState,
            FormError,
          }));
        }
          else if (res.error.code === 1038) {
          showNotify('danger','Common.error','validation.tooManyUploadLimit')
        } else if (res.error.code === 1017) {
          showNotify('danger', 'Common.error', 'validation.fileTypeNotSupported');
        } else if (res.error.code === 1018) {
          showNotify('danger', 'Common.error', 'validaion.fileTypeExceeded');
        } else if (res.error.code === 1024) {
          showNotify('error', 'Common.error', 'Common.error_file_upload');
        } else if (res.error.code === 1032) {
          showNotify('danger', 'Common.error', 'validaion.chooseFile')
        }
      }
      
      
      
      
      
      
    





    });
  };



  const closeAlert = () => {
    setState((prevState) => ({
       ...prevState,
      alert: {
        show: false,
        varient: "",
        message: "",
      },
    }));
  };


   
    const { t } = props;
    if (state.loader) {
      return <Spinner />;
    }


    return (

      // ticket-details
      <div className="h-100">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.support")}
          </title>
        </Helmet>
        <PageTitle
          title="support"
          buttonOn={false}
          AddonIcon={true}
          icon="fa fa-bar-chart"
          group={props.ButtonGroup}
        />
          
        <SupportContent
          changeTicketFilter={changeTicketFilter}
          getTickets={() => getTickets()}
          changeCreateTicket={changeCreateTicket}
          createTicket={createTicket}
          resetTicketFilter={resetTicketFilter}
          searchTickets={searchTickets}
          closeAlert={closeAlert}
          {...state}
        />
          {
                notify.show &&
                    <AlertMessage 
                        message={notify.message}
                        dismiss={notifyDissmissed}
                        type={notify.type}
                        header={notify.header}
                        show={notify.show}
                    />
                }
      </div>
    );

}
export default withTranslation()(withRouter(Support));
