import {BASE_URL,API_KEY} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class InviteService{
    key =localStorage.getItem('apiKey')
    
    // Get SocialEmails
    async getSocialEmails() {
        const response = await fetch(`${BASE_URL}tools/invites_emails`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }

    //edit  to invite
    async editInvite(data) {
        const response = await fetch(`${BASE_URL}tools/invite`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body:JSON.stringify(data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
}