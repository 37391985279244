import React, { useEffect, useState } from 'react';
import {Button, Card,Table} from 'react-bootstrap';
import style from './cart.module.scss';
import {useTranslation} from 'react-i18next';
import { FormInputControl} from '../common';
import {useSelector} from 'react-redux';
function Packages(props) {

    const {t} = useTranslation();
    const currency = useSelector(state=>state.curr);
    const [state,setState] = useState({
        data : [],
        buttonDiabled : false
    });
    useEffect(()=>{
        setState(prev=>({
            ...prev,
            data  : props.cart,
            buttonDiabled : false
        }))
    },[props.cart])
    //calculate the total amount
    const calcTotal = (amount,quantity) =>{
        return (currency.value * amount * quantity).toFixed(currency.precision)
    }

    //check the validation and the move next
    const isValid = () =>{
        let isvalid = false;
        isvalid = state.data.some(element=>{
            return element.error
        })
        if(!isvalid){
            props.next();
        }
    }

    //change Handler 
    const changeHandler = (e,id) =>{
        const {value} = e.target;
        let {data} = state;
        let error = false;
        const newQuantity = data.map((item,index)=>{
            if(index === id){
                if(value>100){
                    error = true;
                    return{
                        ...item,
                        error : {
                            error : 'validation.valueLessthanOrEquelto',
                            field : {
                                count : 100
                            }
                        },
                        qty : value
                    }
                }else if(!value){
                    error = true;
                    return{
                        ...item,
                        error : {
                            error : 'validation.required',
                            field : {
                                field : 'quantity'
                            }
                        },
                        qty : value
                    }
                }else if(value<1) {
                    error = true;
                    return{
                        ...item,
                        error : {
                            error : 'validation.valueGreaterThan0'
                        },
                        qty : value
                    }
                }else{
                    error = false;
                    return { 
                        ...item,
                        error : null,
                        qty : value
                    }
                }
            }
            return{
                ...item,
                error : null
            }
        })
        setState(prev=>({
            ...prev,
            data : newQuantity,
            buttonDiabled : error
        }))
    }
    //calctotal
    const TotalAmount =()=> {
        let totalPrice = state.data.reduce(function (accumulator, item) {
            return accumulator + (item.price*item.qty);
          }, 0);
          return (totalPrice * currency.value).toFixed(currency.precision)
    }
    return (
        <Card className={style.PackageCard}>
            <Card.Body className={style.PackageCardBody}>
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th colSpan="2">
                                {t('Common.items')}
                            </th>
                            <th>
                                {t('Common.quantity')}
                            </th>
                            <th>
                                {t('Common.price')}
                            </th>
                            <th>
                                {t('Common.total')}
                            </th>
                            <th>
                                {t('Common.action')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.data && state.data.map((items,index)=>(
                                <tr key={index}>
                                    <td>
                                        <div className={`${style.checkoutImage}`}>
                                            <img src={items.prod_img} alt="No Image"/>
                                        </div>
                                    </td>
                                    <td>
                                        <span>{items.name}</span>
                                    </td>
                                    <td>
                                        <FormInputControl 
                                            error={items.error}
                                            inputProps={{
                                                name:"quantity",
                                                type:"number",
                                                value:items.qty,
                                                onChange:(e=>{
                                                    changeHandler(e,index)
                                                })
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {currency.currentCurr} {(currency.value * items.price ).toFixed(currency.precision)}
                                    </td>
                                    <td>
                                        {currency.currentCurr} {calcTotal(items.price,items.qty)}
                                    </td>
                                    <td>
                                        <Button className={style.updateBtn} onClick={()=>{
                                            props.updateCart('update',items.rowid, items.qty)
                                        }}>
                                            <i className="fa fa-refresh"></i>
                                            {t('Button.update')}
                                        </Button>
                                        <Button className={style.removeBtn} onClick={()=>{
                                            props.updateCart('remove',items.rowid)
                                        }}>
                                            <i className="fa fa-trash-o"></i>
                                            {t('Button.remove')}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan="5" className={`text-right`}>
                                <b>{t('Common.total')}</b>
                            </td>
                            <td>
                                <b>{currency.currentCurr} {TotalAmount()}</b>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer>
                <Button className={`${style.btnAddon} ${style.btnPrimary}`} onClick={()=>{
                    props.updateCart('remove','all')
                }}>
                    <i className={`fa fa-shopping-cart`}></i>
                    {t('Button.removeAll')}
                </Button>
                <Button 
                    className={`${style.btnPrimary} ${style.floatRight}`}
                    disabled={state.buttonDiabled}
                    onClick={()=>{
                        isValid()
                    }}
                    >
                    {t('Button.continue')}
                </Button>
            </Card.Footer>
        </Card>
    )
}

export default Packages
