import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import DonationService from "../../../service/donation";
import { RecievedContent } from "../../components/donation";
import companyName from "../../../store/mobxStore/companyName";

function RecieveDonation (props) {

    const [state, setState] = useState ({
        loading: true,
        data: [],
        total_count: 0,
    });

 
  const service = new DonationService();
  
    useEffect(() => {
      loadData();
    });
    //no empty array passed in use effect. Need to check if it's right or not.

  const loadData = () => {
    service.recievedDonation().then((res) => {
      if (res.status) {
        setState({
          loading: false,
          data: res.data.data,
          total_count: res.data.total_count,
        });
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

    const { t } = props;
    if (state.loading) {
      return <Spinner />;
    }
    return (
      <div className="h-100">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.recievedDonation")}
          </title>
        </Helmet>
        <PageTitle title="recievedDonation" buttonOn={false} />
        <RecievedContent {...state} />
      </div>
    );

}

export default withTranslation()(withRouter(RecieveDonation));
