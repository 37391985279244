import { React } from "react";
import { Table } from "react-bootstrap";
import style from "./crm.module.scss";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../common";
import { useSelector } from "react-redux";

function LeadDetails(props) {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <div className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_leadDetailsContent}`
          : `${style.leadDetailsContent}`
      }`}>
      <Table striped bordered className={`mb-3`}>
        <tbody>
          <tr>
            <td>{t("profile.firstName")}</td>
            <td>{props.first_name}</td>
          </tr>
          <tr>
            <td>{t("profile.lastName")}</td>
            <td>
              {props.last_name} &nbsp;
              {props.last_name === "NA" && (
                <span
                  className={style.addSpan}
                  onClick={() => props.showModal("", "edit")}
                >
                  {t("Crm.addToGet", {
                    field: "profile.lastName",
                    percentage: 10,
                  })}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>{t("Common.email")}</td>
            <td>
              {props.email_id}&nbsp;
              {props.email_id === "NA" && (
                <span
                  className={style.addSpan}
                  onClick={() => props.showModal("", "edit")}
                >
                  {t("Crm.addToGet", { field: "Common.email", percentage: 15 })}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>{t("Common.skype")}</td>
            <td>
              {props.skype_id}&nbsp;
              {props.skype_id === "NA" && (
                <span
                  className={style.addSpan}
                  onClick={() => props.showModal("", "edit")}
                >
                  {t("Crm.addToGet", { field: "Common.skype", percentage: 15 })}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>{t("profile.mobile")}</td>
            <td>
              {props.mobile_no}&nbsp;
              {props.mobile_no === "NA" && (
                <span
                  className={style.addSpan}
                  onClick={() => props.showModal("", "edit")}
                >
                  {t("Crm.addToGet", {
                    field: "profile.mobile",
                    percentage: 15,
                  })}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>{t("Common.countryName")}</td>
            <td>
              {props.country}&nbsp;
              {props.country === "NA" && (
                <span
                  className={style.addSpan}
                  onClick={() => props.showModal("", "edit")}
                >
                  {t("Crm.addToGet", {
                    field: "Common.country",
                    percentage: 15,
                  })}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>{t("Common.description")}</td>
            <td>{props.description}&nbsp;</td>
          </tr>
          <tr>
            <td>{t("Crm.levelOfInterest")}</td>
            <td>{props.interest_status}&nbsp;</td>
          </tr>
          <tr>
            <td>{t("Crm.assignedTo")}</td>
            <td>{props.added_by_name}&nbsp;</td>
          </tr>
          <tr>
            <td>{t("Crm.dateAdded")}</td>
            <td>{props.date}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <CustomButton
        variant="primary"
        onClick={() => props.showModal("", "edit")}
      >
        {t("Button.editDetails")}
        &nbsp;
        <i className="fa fa-arrow-circle-right"></i>
      </CustomButton>
    </div>
  );
}

export default LeadDetails;
