import React from 'react';
import {Alert} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
function AlertBs(props) {

    const {t} = useTranslation();
    return (
        <Alert variant={props.varient} show={props.show}  dismissible onClose={props.close}>
            {t(props.message,props.field)}
        </Alert>
    )
}

export default AlertBs
