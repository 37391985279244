import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18next";
import * as serviceWorker from './serviceWorker';
import { createStore } from 'easy-peasy';
import { Provider}    from  'react-redux';
import { StoreProvider } from 'easy-peasy';
import allReducer     from './store/reducer';
import Spinner from './app/shared/Spinner';
import 'animate.css/animate.css';
import 'font-awesome/css/font-awesome.min.css';
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";




const store = createStore(
  allReducer,  
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
ReactDOM.render(
  <Provider store={store}>
    <StoreProvider store={store}>
      <React.Suspense fallback={<Spinner/>}>
        <I18nextProvider i18n={i18n}>


         <App /> 
      
        </I18nextProvider>
      </React.Suspense> 
      </StoreProvider>
  </Provider>
, document.getElementById('root'));

serviceWorker.unregister();