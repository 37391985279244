import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import style from './party.module.scss';
import commonStyle from '../common/Common.module.scss';
import {useTranslation} from 'react-i18next';
// import { IMG_URL } from '../../../configuration/apiconfig';
function StepThree(props) {
    const {t} = useTranslation();
    return (
        <Card className={`${style.Card}`}>
            <Card.Body className={`${style.CardBody}`}>
                    <legend>
                        <span>{t('Common.stepThree_EnterTheDateAndTimeOftheParty')}</span>
                    </legend>
                    
            </Card.Body>
            <Row>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label className={`${commonStyle.required}`}>{t('Common.form')}</Form.Label>
                        <span className={style.datePicker}>
                            <Form.Control
                                type="text"
                                name="from"
                                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                                value={props.partyContent.from}
                                onChange={props.changeHandler}
                                readOnly
                            />
                        </span>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label className={`${commonStyle.required}`}>{t('Common.form')}</Form.Label>
                        <span className={style.datePicker}>
                            <Form.Control
                                type="text"
                                name="from"
                                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                                value={props.partyContent.from}
                                onChange={props.changeHandler}
                                readOnly
                            />
                        </span>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label className={`${commonStyle.required}`}>{t('Common.form')}</Form.Label>
                        <span className={style.datePicker}>
                            <Form.Control
                                type="text"
                                name="from"
                                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                                value={props.partyContent.from}
                                onChange={props.changeHandler}
                                readOnly
                            />
                        </span>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label className={`${commonStyle.required}`}>{t('Common.form')}</Form.Label>
                        <span className={style.datePicker}>
                            <Form.Control
                                type="text"
                                name="from"
                                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                                value={props.partyContent.from}
                                onChange={props.changeHandler}
                                readOnly
                            />
                        </span>
                    </Form.Group>
                </Col>
            </Row>
        </Card>
    )
}

export default StepThree
