import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation }  from 'react-i18next';
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import {NewsContent} from '../../components/tools';
//import NewsContentNew from '../../components/tools/NewsContentNew';

function News(props){
    const {t} = props
    return (
      <div className="h-100">
          <Helmet>
              <title>{companyName.companyName} | {t('Sidemenu.news')}</title>
          </Helmet>
          <PageTitle 
              title="news"  
              buttonOn={false}  
          />
          <NewsContent/>
      </div>
  );
}
export default withTranslation()(withRouter(News))