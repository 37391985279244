import React from "react";
import style from "./network.module.scss";
import { TreeNode } from "./";
import { useState } from "react";
import GenealogyService from "../../../service/tree/genealogy";
import { useHistory } from "react-router-dom";
const TreeContent = (props) => {
  console.log("TreeContent", props);
  const service = new GenealogyService();
  const history = useHistory();
  const [expand, setExpand] = useState({
    show: false,
    data: [],
    level: 1,
    setChange: {},
    hideparent: true,
  });

  if (props.searchExpand) {
    expand.data = [];
  }
  const showExpand = (data, showFunction, userId, expandlevel, name) => {
    props.setSearchExpand(false);

    let { setChange } = expand;
    if (setChange["level" + expandlevel]) {
      setChange["level" + expandlevel][name] = showFunction;
    } else {
      let tempLevel = {
        ["level" + expandlevel]: {
          [name]: showFunction,
        },
      };
      Object.assign(setChange, tempLevel);
    }
    for (const [key] of Object.entries(setChange["level" + expandlevel])) {
      setChange["level" + expandlevel][key](false);
    }
    let treeData = expand.data;
    treeData.length = expandlevel;
    treeData.push(data);
    showFunction(true);
    setExpand((prev) => ({
      ...prev,
      show: true,
      data: treeData,
      hideparent: false,
    }));

    service.getTreelist(name).then((res) => {
      if (res.status) {
        treeData.pop();

        treeData.push(res.data.TreeData[0]);
        setExpand((prev) => ({
          ...prev,
          data: treeData,
        }));
      } else {
        if (res.error.code === 1002) {
          history.push("/logout");
        }
      }
    });
  };
  const closeExpand = (expandLevel) => {
    let treeData = expand.data;
    treeData.length = expandLevel;
    setExpand((prev) => ({
      ...prev,
      data: treeData,
      // show  :false,
    }));
  };
  return (
    <>
      <div className={"rank-section"}>
        {props.rankData.map((item, index) => {
          return (
            <div className="tree-icon-container">
              <img
                src={`https://admin.netbigg.it/uploads/images/tree/${item.tree_icon}`}
              />
              <h6>{item.rank_name}</h6>
            </div>
          );
        })}

        {/* <div className="tree-icon-container">
          <img src="https://admin.netbigg.it/uploads/images/tree/tree_691150.jpg" />
          <h6>Bronze</h6>
        </div>
        <div className="tree-icon-container">
          <img src="https://admin.netbigg.it/uploads/images/tree/tree_691150.jpg" />
          <h6>Bronze</h6>
        </div> */}
      </div>
      <div className={style.treeContent}>
        <div className={style.treeChart}>
          <div className={style.main}>
            <TreeNode
              {...props.data[0]}
              expand={showExpand}
              closeExpand={closeExpand}
              setSearchExpand={props.setSearchExpand}
              depthLevel={0}
              toolTipConfig={props.tooltipConfig}
              callParent={props.parentNode}
              firstLevel={true}
              storeUrl={props.storeUrl}
              filterUser={props.filterUser}
            />
          </div>
        </div>
        {expand.show &&
          expand.data.map((items, index) => {
            return (
              <div className={style.treeChart} key={index}>
                <div className={style.main}>
                  <TreeNode
                    {...items}
                    depthLevel={index + 1}
                    expand={showExpand}
                    searchclose={props.searchclose}
                    setSearchExpand={props.setSearchExpand}
                    closeExpand={closeExpand}
                    callParent={props.parentNode}
                    toolTipConfig={props.tooltipConfig}
                    storeUrl={props.storeUrl}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default TreeContent;
