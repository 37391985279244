import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import { ReferralContent } from '../../components/tree';





const ReferralMember = (props) => {


    const { t } = props;
    return (




        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.referralMembers')}</title>
            </Helmet>
            <PageTitle
                title="referralMembers"
                buttonOn={false}
            />
            <ReferralContent />
        </div>


    )




}

export default withTranslation()(ReferralMember)
