import React from 'react';
import style from './tools.module.scss';
import { Modal, Form ,Table, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
// import moment from 'moment';
const EditLead = (props) => {
    const {t}  = useTranslation();
    if(props.lead) {
        const comments = props.lead.comments.map((comment, key) => {
            return  ( <tr>
                <td>{`${t('lead.comment')} ${++key}`}</td>
                <td>:</td>
                <td>{comment.description}</td>
            </tr>);
        });

        let statuses = ['Ongoing', 'Accepted', 'Rejected'];

        statuses = statuses.map((status, key) => {
            return ( 
              <option key={key} value={status}>
                {status}
              </option>
            );
        });

        return ( 
                <Modal
                    show={props.showEditModal} 
                    onHide={props.handleClose} 
                    dialogClassName={`${style.ModalDialog} animate__animated animate__fadeInRight ${props.showEditModal?'animate__fadeInRight':'animate__fadeOutRight'} FundTransferModal`}
                >
                  <Modal.Header 
                      closeButton 
                      bsPrefix={style.ModalHeader}
                  >
                    <Modal.Title>{t('lead.leadDetails')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Table>
                        <tbody>
                          <tr>
                            <td>{t('Common.name')}</td>
                            <td>:</td>
                            <td>{`${props.lead.first_name} ${props.lead.last_name}`}</td>
                          </tr>
                          <tr>
                            <td>{t('Common.sponsorName')}</td>
                            <td>:</td>
                            <td>{props.lead.sponser_name}</td>
                          </tr>
                          <tr>
                            <td>{t('Common.email')}</td>
                            <td>:</td>
                            <td>{props.lead.email}</td>
                          </tr>
                          <tr>
                            <td>{t('Common.skype')}</td>
                            <td>:</td>
                            <td>{props.lead.skype_id}</td>
                          </tr>
                          <tr>
                            <td>{t('Common.phone')}</td>
                            <td>:</td>
                            <td>{props.lead.phone}</td>
                          </tr>
                          <tr>
                            <td>{t('profile.country')}</td>
                            <td>:</td>
                            <td>{props.lead.country}</td>
                          </tr>
                          <tr>
                            <td>{t('Common.date')}</td>
                            <td>:</td>
                            <td>{props.lead.date}</td>
                          </tr>
                          <tr>
                            <td>{t('Common.description')}</td>
                            <td>:</td>
                            <td>{props.lead.description}</td>
                          </tr>
                         {comments}
                          <tr>
                            <td>{t('lead.addComment')}</td>
                            <td>:</td>
                            <td>
                              <Form.Control size="xs" type="text" value={props.new_comment} onChange={props.newComment}/>
                            </td>
                          </tr>
    
                          <tr>
                            <td>{t('Common.status')}</td>
                            <td>:</td>
                            <td>
                              <select value={props.lead.status} onChange={props.updateStatus}className="form-control form-control-xs" name="">
                                {statuses}
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                  </Modal.Body>
    
                  <Modal.Footer>
                    <Button onClick={props.handleClose} type="button" variant="secondary">{t('Button.close')}</Button>
                    <Button type="submit" onClick={props.updateLead} variant="primary">{t('Button.update')}</Button>
                  </Modal.Footer>
                </Modal>
        );
    }
    return '';
}
 
export default EditLead;