import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import axios from "axios";
import API from "../../api/api";

export default class ProfileService {
  key = localStorage.getItem("apiKey");

  getDetails = async () => {
    return await API.API.get("profile/view")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  deleteImage = async () => {
    const response = await API.API.get("profile/image_delete");
    if (response.status === 204) {
      return {
        status: true,
      };
    } else {
      const res = response;
      return res;
    }
  };

  UpdatePersonalDetails = async (data) => {
    return await API.API.put("profile/personalDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdateContactDetails = async (data) => {
    return await API.API.put("profile/contactDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdateBankDetails = async (data) => {
    return await API.API.put("profile/bankDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdatePaymentDetails = async (data) => {
    return await API.API.put("profile/paymentMethod", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  UpdateSettings = async (data) => {
    return await API.API.put("profile/settingstDetails", data)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  upgradeDetails = async () => {
    return await API.API.get("profile/upgrade_package")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  getPaymentData = async (type) => {
    return await API.API.get("payment/payment_methods?type=" + type)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  getKYCDetails = async () => {
    return await API.API.get("profile/kyc_uploads")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  uploadFile = async (formData) => {
    return await API.API.post("profile/kyc_file_uploads", formData)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  deletefile = async (id) => {
    return await API.API.post("profile/remove_kyc", id)
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  subscriptionDetails = async () => {
    return await API.API.get("profile/subscription_details")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };

  uploadImage = async (image) => {
    return await API.API.post("profile/image", image)
      .then((res) => {
        if (res.status ==204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

subscriptionSubmit = async (data) => {
  return await API.API.post("profile/subscription", data)
      .then((res) => {
        if (res.status ==204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  }

  packageUpgradeSubmit = async (data)=>{
    return await API.API.post("upgrade/upgrade", data)
    .then((res) => {
      if (res.status ==204) {
      
        return {
          status: true ,
        };
      } else {
        return res;
      }
    })
    .catch((error) => console.log(error));
  }

  



  //async subscriptionSubmit ()
    // const response = await fetch(BASE_URL + "profile/subscription", {
    //   //url
    //   method: "POST", //method
    //   headers: {
    //     "api-key": this.key,
    //     "access-token": UserStore.key,
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // });
    // if (response.status === 204) {
    //   return {
    //     status: true,
    //   };
    // } else {
    //   const res = await response.json();
    //   return res;
    // }

  // async packageUpgradeSubmit(data) {
  //   const response = await fetch(BASE_URL + "upgrade/upgrade", {
  //     //url
  //     method: "POST", //method
  //     headers: {
  //       "api-key": this.key,
  //       "access-token": UserStore.key,
  //       "content-type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  //   if (response.status === 204) {
  //     return {
  //       status: true,
  //     };
  //   } else {
  //     const res = await response.json();
  //     return res;
  //   }
  // }





  async getCaptcha() {
    const response = await fetch(
      BASE_URL + "profile/forget_transaction_password",
      {
        //url
        method: "GET", //method
        headers: {
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    if (response.status === 200) {
      console.log(response)
      return response.blob();
    } else {
      const res = await response.json();
      return res;
    }
  }

  // async uploadImage(image) {
  //   const response = await fetch(BASE_URL + "profile/image", {
  //     //url
  //     method: "POST", //method
  //     headers: {
  //       "api-key": this.key,
  //       "access-token": UserStore.key,
  //     },
  //     body: image,
  //   });
  //   if (response.status === 204) {
  //     return {
  //       status: true,
  //     };
  //   } else {
  //     const res = await response.json();
  //     return res;
  //   }
  // }

  // async subscriptionDetails() {
  //   const response = await fetch(BASE_URL + "profile/subscription_details", {
  //     //url
  //     method: "GET", //method
  //     headers: {
  //       //passing header
  //       // 'Accept'        : 'application/json',
  //       "Content-Type": "application/json",
  //       "api-key": this.key,
  //       "access-token": UserStore.key,
  //     },
  //   });
  //   const res = await response.json();
  //   return res;
  // }

  // async deletefile(id) {
  //   const response = await fetch(BASE_URL + "profile/remove_kyc", {
  //     //url
  //     method: "POST", //method
  //     headers: {
  //       //passing header
  //       // 'Accept'        : 'application/json',
  //       "Content-Type": "application/json",
  //       "api-key": this.key,
  //       "access-token": UserStore.key,
  //     },
  //     body: JSON.stringify(id),
  //   });
  //   const res = await response.json();
  //   return res;
  // }

  // async uploadFile(formData) {
  //   const response = await fetch(BASE_URL + "profile/kyc_file_uploads", {
  //     //url
  //     method: "POST", //method
  //     headers: {
  //       //passing header
  //       // 'Accept'        : 'application/json',
  //       // 'Content-Type'  : 'application/json',
  //       "api-key": this.key,
  //       "access-token": UserStore.key,
  //     },
  //     body: formData,
  //   });
  //   const res = await response.json();
  //   return res;
  // }
  // async getKYCDetails() {
  //   const response = await fetch(BASE_URL + "profile/kyc_uploads", {
  //     //url
  //     method: "GET", //method
  //     headers: {
  //       //passing header
  //       // 'Accept'        : 'application/json',
  //       // 'Content-Type'  : 'application/json',
  //       "api-key": this.key,
  //       "access-token": UserStore.key,
  //     },
  //   });
  //   const res = await response.json();
  //   return res;
  // }

  // // getPaymentData
  // async getPaymentData(type = "") {
  //   const response = await fetch(
  //     `${BASE_URL}payment/payment_methods?type=${type}`,
  //     {
  //       //url
  //       method: "GET", //method
  //       headers: {
  //         //passing header
  //         // 'Accept'        : 'application/json',
  //         "Content-Type": "application/json",
  //         "api-key": this.key,
  //         "access-token": UserStore.key,
  //       },
  //     }
  //   );
  //   const res = await response.json();
  //   return res;
  // }

  //get the package upgrade details
  //    async upgradeDetails() {
  //     const response = await fetch(BASE_URL + "profile/upgrade_package", {
  //       //url
  //       method: "GET", //method
  //       headers: {
  //         //passing header
  //         // 'Accept'        : 'application/json',
  //         "Content-Type": "application/json",
  //         "api-key": this.key,
  //         "access-token": UserStore.key,
  //       },
  //     });
  //     const res = await response.json();
  //     return res;
  //   }
  // //update personal details data
  // async UpdatePersonalDetails(data) {
  //     return new Promise((resolve, reject) => {
  //       fetch(BASE_URL + "profile/personalDetails", {
  //         method: "put",
  //         headers: {
  //           // 'Accept'        : 'application/json',
  //           "Content-Type": "application/json",
  //           "api-key": this.key,
  //           "access-token": UserStore.key,
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then((response) => {
  //           return response.json();
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     });
  //   }

  //   async deleteImage() {
  //     const response = await fetch(BASE_URL + "profile/image_delete", {
  //       //url
  //       method: "GET", //method
  //       headers: {
  //         "api-key": this.key,
  //         "access-token": UserStore.key,
  //       },
  //     });
  //     if (response.status === 204) {
  //       return {
  //         status: true,
  //       };
  //     } else {
  //       const res = await response;
  //       return res;
  //     }
  //   }

  // async getDetails() {
  //     return new Promise((resolve, reject) => {
  //         fetch(BASE_URL + 'profile/view', {
  //             method: 'GET',
  //             headers: {
  //                 'Accept': 'application/json',
  //                 'Content-Type': 'application/json',
  //                 'api-key': this.key,
  //                 'access-token': UserStore.key
  //             },
  //         })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //change country name select state
  async countryChange(countryID) {
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + "profile/countryChange?country_id=" + countryID, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //update contact details
  //   async UpdateContactDetails(data) {
  //     return new Promise((resolve, reject) => {
  //       fetch(BASE_URL + "profile/contactDetails", {
  //         method: "PUT",
  //         headers: {
  //           // 'Accept'        : 'application/json',
  //           "Content-Type": "application/json",
  //           "api-key": this.key,
  //           "access-token": UserStore.key,
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then((response) => {
  //           return response.json();
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     });
  //   }

  //   //update bank detials
  //   async UpdateBankDetails(data) {
  //     return new Promise((resolve, reject) => {
  //       fetch(BASE_URL + "profile/bankDetails", {
  //         method: "PUT",
  //         headers: {
  //           // 'Accept'        : 'application/json',
  //           "Content-Type": "application/json",
  //           "api-key": this.key,
  //           "access-token": UserStore.key,
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then((response) => {
  //           return response.json();
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     });
  //   }

  //   //update payment details
  //   async UpdatePaymentDetails(data) {
  //     return new Promise((resolve, reject) => {
  //       fetch(BASE_URL + "profile/paymentMethod", {
  //         method: "PUT",
  //         headers: {
  //           // 'Accept'        : 'application/json',
  //           "Content-Type": "application/json",
  //           "api-key": this.key,
  //           "access-token": UserStore.key,
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then((response) => {
  //           return response.json();
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     });
  //   }

  //update settings
  //   async UpdateSettings(data) {
  //     return new Promise((resolve, reject) => {
  //       fetch(BASE_URL + "profile/settingstDetails", {
  //         method: "PUT",
  //         headers: {
  //           // 'Accept'        : 'application/json',
  //           "Content-Type": "application/json",
  //           "api-key": this.key,
  //           "access-token": UserStore.key,
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then((response) => {
  //           return response.json();
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     });
  //   }

  //get the KYC Detials

  //get the KYC Detials

  //remove the docfiles

  // get subscription details

  //update profile image


  //delete the profile pic

  //submit the subscription detials


  //submit the package_upgrade
 
}
