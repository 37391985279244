import React from 'react';
import style from './package.module.scss';
import {useTranslation} from 'react-i18next';
function UpgradePackageList(props) {
    const {t} = useTranslation();
        
    // Upgrade Package List
    let list = props.upgrade_list.map(function(item) {
        return (
            <option key={item.product_id} value={item.product_id}>{item.product_name}</option>
        );
    });

    
    return (
        <div>
            <br />
            <div className={style.selectPackage}>
                <label>{t(`Common.packageToUpgrade`)}</label><br/>
                <select onChange={props.selectPackage} name="product_id" id="product_id2">
                    <option  value="" >{t('Common.selectPackage')}</option>
                    {list}
                </select>
            </div>   
        </div>
    )
}

export default UpgradePackageList
