import React from "react";
import { useTranslation } from "react-i18next";
import style from "./registration.module.scss";
import { useSelector } from "react-redux";

function Paneltilte(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_heading}`
          : `${style.heading}`
      }`}
    >
      <h5>{t("registration.title." + props.tilte)}</h5>
    </div>
  );
}

export default Paneltilte;
