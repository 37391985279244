import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { CustomButton, DatePickers, FormInputControl } from '../common';
import {useTranslation} from 'react-i18next';
import style from './crm.module.scss';
import moment from 'moment';
import { useHistory } from 'react-router';
import CrmService from '../../../service/crm/Crm';
const NextFollowUp = (props) => {
    const {t}  = useTranslation();
    const history = useHistory();

    const InitialForm = {
        followup_date :'',
        id  :'',
        FormError: {
            followup_date: "",
        },
    }
    const [state,setState]= useState({
        ...InitialForm,
        nextfollouploader:false,
      
    });
    const service = new CrmService();
    const dateChange = (date,id)=>{
        let c_date= moment(date).format('yyyy-MM-DD')
        setState(prev=>({
            ...prev,
            [id]:c_date,
            FormError : {
                ...prev.FormError,
                [id] : ''
            }
        }))
    }


 
    const onSubmitForm = e =>{
        e.preventDefault();
        let formData = new FormData();
        let isValid=true;
        let FormError ={}
        if(!state.followup_date){
            isValid=false;
            FormError.followup_date={
                error : 'validation.required',
                field : {
                    field : 'followup_date'
                }
            }
        }
        else if(state.followup_date<moment(new Date).format('yyy-MM-DD')){
            isValid = false;
            FormError.followup_date={
                error : 'validation.dategreateThancurrentDate',
                field : {
                    field : 'followup_date'
                }
            }
        }

        setState(prev=>({
            ...prev,
            FormError : {
                ...prev.FormError,
                ...FormError
            }
          
        }))
        if(isValid){
        formData.append('followup_date',state.followup_date);
        formData.append('id',props.id);
        setState(prev=>({
            ...prev,
            nextfollouploader:true 
        }))
        service.AddNextFollowup(formData).then((res) =>{
            if(res.status){
                setState(prev=>({
                    ...prev,
                    nextfollouploader:false 
                }))
                if(props.callback){
                    props.callback();
                }
            }
            else{
                if(res.error.code === 1002){
                    history.push('/logout')
                }else if(res.error.code === 1004){

                }
            }
        });

        }
   
    }
    return (
        <div className={`${style.panel} ${style.papanel_defaultnel}`}>
            <div className={`${style.panel_body}`}>
            <Form onSubmit={onSubmitForm} >
                <FormInputControl 
                    label={t('Crm.CurrentFollowupDate')}
                    inputProps={{
                        type:'text',
                        name:'current_follow_up',
                        value:props.data.followup_date,
                        disabled:true

                    }}
                />
                <DatePickers 
                    label={t('Crm.NextFollowupDate')}
                    start={state.followup_date}
                    datechange={dateChange}
                    placeholderText={t('Crm.NextFollowupDate')}
                    id='followup_date'
                    required={true}
                    minDate={moment(props.data.followup_date).format('yyyy-MM-DD')}
                    error={state.FormError.followup_date}
                />
                <CustomButton
                    type="submit"
                    variant="primary"
                >
                {state.nextfollouploader ?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''}
                    
                    {t('Button.updateFollowupdate')}
                </CustomButton>
            </Form>
        </div>
    </div>
    );
}
 
export default NextFollowUp;