import React, { useEffect } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';





function About(props) {


    return (

        <div id="about" className="aboutclass">
         
          <div className="container"  dangerouslySetInnerHTML={{ __html: props.data.policy_data && props.data.policy_data.content?.about }}></div>
         
               
        </div>

    )
}

export default About





