import React from 'react';
import {Card} from  'react-bootstrap';
import style from './party.module.scss';
import {useTranslation} from 'react-i18next';
import {CustomButton} from '../common';
function StepFive(props) {
    const {t} = useTranslation();
    return (
        <Card className={`${style.Card}`}>
            <Card.Body className={`${style.CardBody}`}>
                <legend>
                    <span>{t('Common.stepFive')}</span>
                </legend>
                <p>
                    {t('party.finalStepDiscription')}
                </p>
                <CustomButton variant="primary" onClick={props.createParty}>
                    {t('Button.setupMyParty')}
                </CustomButton>
            </Card.Body>
        </Card>
    )
}

export default StepFive
