import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import PageTitle from '../../components/common/pageTitle';
import { PromotionalToolsContent } from '../../components/tools';
import InviteService from '../../../service/tools/invites';
import Spinner from '../../shared/Spinner'
import companyName from '../../../store/mobxStore/companyName';



function PromotionalTools(props) {
  let service = new InviteService();
  const [state, setState] = useState({
    loading: true,
    social_emails: [],
    banners: [],
    inviteHistory: [],
    InviteText: [],
    socialInvite: {
      fb: [],
      insta: [],
      twitter: []
    },
    alert: {
      show: false,
      message: '',
      varient: ''
    }

  })

  useEffect(() => {
    const { state } = props.location;
    if (state) {
      setState(prevstate => ({
        ...prevstate,
        alert: {
          show: true,
          message: `validation.${state.message}`,
          varient: state.error ? 'danger' : 'success'
        }
      }));
    }
    getSocialEmails();

  }, []);

  const getSocialEmails = () => {
    service.getSocialEmails()
      .then(res => {
        if (res.status) {
          setState(prevstate => ({
            ...prevstate,
            loading: false,
            social_emails: res.data.social_emails,
            banners: res.data.banners,
            inviteHistory: res.data.invite_history,
            InviteText: res.data.text_invite,
            socialInvite: {
              fb: res.data.social_invite.fb,
              insta: res.data.social_invite.instagram,
              twitter: res.data.social_invite.twitter
            }
          }))
        } else {
          if (res.error.code === 1002) {
            props.history.push('/logout')
          } else if (res.error.code === 1057) {
            props.history.push({
              pathname: '/dashboard',
              state: {
                error: true,
                message: 'permissionDenied'
              }
            })
          }
        }
      });
  }

  const { t } = props;

  return (
    <div className="h-100">
      <Helmet>
        <title>{companyName.companyName} | {t('Sidemenu.promotional_tools')}</title>
      </Helmet>
      <PageTitle
        title="promotional_tools"
        buttonOn={false}
      />
      {state.loading ?
        <Spinner />
        :
        <PromotionalToolsContent
          {...state}
        />
      }
      {/* <ViewNews /> */}
    </div>
  );
}
export default withTranslation()(withRouter(PromotionalTools))
