import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class ticketService{
    key=localStorage.getItem('apiKey')
    
    // Get Filters
    async getTicketFormFilters() {
        const response = await fetch(`${BASE_URL}ticket/filters`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            }
        })
        return await response.json();
    }

    // Get Tickets
    async getTickets(params) {
        const url = new URL(`${BASE_URL}ticket/tickets`);
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            }
        })
        return await response.json();
    }

    // Create Ticket
    async createTicket(form_data) {
        const url = new URL(`${BASE_URL}ticket/tickets`);
        // url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: form_data
        })
        return await response.json();
    }

    // Get Tickets
    async getTicketTimeLine(ticket_id) {
        const url = new URL(`${BASE_URL}ticket/ticket_time_line/${ticket_id}`);
        const response = await fetch(url, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            }
        })
        return await response.json();
    }

    // Get Tickets
    async getTicketDetails(ticket_id) {
        const url = new URL(`${BASE_URL}ticket/ticket_details/${ticket_id}`);
        const response = await fetch(url, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            }
        })
        return await response.json();
    }

    // save Ticket Message
    async saveMessage(ticket_id, form_data) {
        const url = new URL(`${BASE_URL}ticket/save_ticket/${ticket_id}`);
        
        const response = await fetch(url, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: form_data
        })
        return await response.json();
    }
}