import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class LeadService{
    key =localStorage.getItem('apiKey')
    // Get Leads
    async getLeads(keyword = "", length = 10, start = 0, order = "", direction = "ASC") {
        const response = await fetch(`${BASE_URL}tools/leads?length=${length}&start=${start}&order=${order}&direction=${direction}&keyword=${keyword}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }

    //submit the subscription detials 
    async updateLead(data, id){
        const response = await fetch(`${BASE_URL}tools/leads/${id}`, {     //url
            method: 'PUT',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key,
                'content-type'  : "application/json"
            },
            body  : JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
}