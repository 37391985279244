import {extendObservable} from 'mobx';
class companyName {
    constructor(){
        extendObservable(this,{
            companyName: '',
            apiKey : ''
        });
        let res =localStorage.getItem('apiKey')
        if (res) {
            this.apiKey=res;
        }
    }
}

export default new companyName(); 