import React from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import style from './tools.module.scss';
import {useTranslation} from 'react-i18next';
import {EmailInvites,BannerInvites,TextInvite,SocialInvite} from './promotionalTools';
import {AlertBs} from '../common';
const PromotionalToolsContent = (props) => {
    const {t} = useTranslation();
    return ( 
        <div className={style.inviteContainer}>
            <AlertBs
                {...props.alert}
            />
           <Tabs defaultActiveKey="email" id="tab">
                <Tab eventKey="email" title={t('profile.email')}>
                    <EmailInvites
                        social_emails={props.social_emails}
                        inviteHistory={props.inviteHistory}
                    />
                </Tab>

                <Tab eventKey="banner_invites" title={t('Common.bannerInvite')}>
                    <BannerInvites
                        banners={props.banners}
                    />
                </Tab>
                <Tab eventKey="textInvite" title={t('Common.textInvite')}>
                    <TextInvite
                        inviteText={props.InviteText}
                    />
                </Tab>
                <Tab eventKey="socialInvite" title={t('Common.socialInvite')}>
                    <SocialInvite
                        data={props.socialInvite}
                    />
                </Tab>
            </Tabs> 
        </div>
     );
}
export default PromotionalToolsContent;