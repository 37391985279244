
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import companyName from '../../../../store/mobxStore/companyName'
import Forms from '../../../components/auth/Forms'
import ResetPasswordService from '../../../../service/Auth/ResetPassword';
import ResetButton from '../../../components/auth/ResetButton'
import { BASE } from '../../../../configuration/apiconfig';
import { Alert, Form } from 'react-bootstrap';
import { AlertMessage } from '../../../components/common';
import { Container } from 'react-bootstrap';
import style from '../../../style/App.module.scss';
import Brand from '../../../components/auth/Brand';
import { Language } from '../../../components/common/';
import { useParams, useHistory } from 'react-router-dom';
import { islogged } from '../../../../store/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConnectingAirportsOutlined } from '@mui/icons-material';



const mapStateToProps = (state) => {
    return { proPs: state }

};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ islogged }, dispatch)
}


function ResetTranPassword({ t,proPs }) {


    var captchvalue;
    const recaptchaRef = React.createRef();
    const history = useHistory();

    let loginContainer = {
        marginTop: '-1px',
        marginLeft: '23px',
        color: '#a94442',
        fontSize: "12px"

    }
    const service = new ResetPasswordService();

    const notifyInintialState = {
        show: false,
        message: '',
        type: '',
        header: ''
    }


    const [state, setState] = useState({
        newpassword: '',
        confpassword: '',
        captcha: '',
        captchvalue: '',
        isLoggedError: false,
        user_name: '',
        key: '',
        notify: notifyInintialState,
        captchaImage: '',
        FormError: {
            newpassword: "",
            confpassword: "",
            captcha: ""
        },
        btnDisabled: false
    })
    const [show, setShow] = useState(false);
    const path = useParams();


    const formValid = formError => {
        let valid = true;
        Object.values(formError).forEach(
            val => val.length > 0 && (valid = false)
        );
        return valid;
    }

    onchange = (e) => {
        const service = new ResetPasswordService();
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'confpassword') {
            if (state.newpassword == state.confpassword) {
                setState(prevState => ({
                    ...prevState,
                    FormError: {
                        ...prevState.FormError,
                        confpassword: ''
                    }
                }));

            }

        }


    }
    useEffect(() => {
        // const {state} = props.location;
        const { id } = path.resetkey;
        service.getTranuserName(path.resetkey)
            .then(res => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        user_name: res.data.user_name,
                        key: res.data.key
                    }));
                    reloadCaptcha(res.data.user_name);

                }
                else {
                    history.push('/logout')
                }
            });

    }, []);

    const blurhandler = (name) => {
        if (name === 'confpassword') {
            if (state.newpassword != state.confpassword) {
                setState(prevState => ({
                    ...prevState,
                    FormError: {
                        ...prevState.FormError,
                        confpassword: 'unmatch'
                    }
                }));

            }



        }

    }


    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        switch (name) {
            case 'newpassword':
                FormError.newpassword = value.length === 0 ? "newpasswd" : "";
                break;
            case 'confpassword':
                FormError.confpassword = value.length === 0 ? "confpassword" : "";
                break;
            case 'captcha':
                FormError.captcha = value.length === 0 ? "captchareq" : "";
                break;
            default:
                break;
        }

        setState(prevState => ({
            ...prevState,
            FormError
        }));

    }
    const checkLength = (value, name) => {
        const FormError = state.FormError;
        if (value.length < 8) {
            FormError.newpassword = "minlength";
        }

    }
    const validatepasswords = (newPassword, confPasswod) => {
        const FormError = state.FormError;
        if (newPassword!= confPasswod) {
            setState(prevState => ({
                ...prevState,
                FormError: {
                    ...prevState.FormError,
                    confpassword: 'unmatch'
                }
            }));

        }

    }

    const onSubmit = async e => {

        e.preventDefault();//prevent default  
        requiredValid(state.newpassword, 'newpassword');
        requiredValid(state.confpassword, 'confpassword');
        requiredValid(state.captcha, 'captcha');
        checkLength(state.newpassword, 'newpassword');
        validatepasswords(state.newpassword, state.confpassword);
        if (formValid(state.FormError)) {
            const formData = {
                confirm_pass: state.confpassword,
                pass: state.newpassword,
                captcha: state.captcha,
                user_name: state.user_name,
                reset_password_submit: true,
                key: state.key
            };
            service.resetTranPassword(formData).then(res => {
                if (res.status) {
                    showNotify('success', 'Common.success', 'validation.Passwowrdreset');
                    if(proPs.isLogged){
                        setTimeout(() => {
                            history.push('/profile')
                          }, 3000)
                 
                    }
                    else {
                     
                        setTimeout(() => {
                            history.push('/login')
                          }, 3000)
                    }
                }
                else {
                    if(res.error.code==1030){
                        showNotify('danger', 'error', res.error.description);
                    }
                    else{
                        showNotify('danger', t('Common.error'), t('validation.invalidCaptcha'));
                    }
                
                }
            })

        }

    }

    const showNotify = (type, header, message) => {
        setState(prev => ({
            ...prev,
            notify: {
                ...prev.notify,
                show: true,
                type: type,
                header: t(header),
                message: t(message)
            }
        }))
    }

    const reloadCaptcha = (userName) => {
        service.getCaptcha(userName).then((res) => {
            let outside = URL.createObjectURL(res);
            setState(prev => ({
                ...prev,
                captchaImage: outside
            }))
        })
    }

    const notifyDissmissed = () => {
        setState(prev => ({
            ...prev,
            notify: notifyInintialState
        }))
    }


    const CaptchaChage = (value) => {
        setState(prevState => ({
            ...prevState,
            captcha: value,
            FormError: {
                ...prevState.FormError,
                captcha: ''

            }
        }));
    }


    return (


        <>
            <div className="mb-2">
                <Helmet>
                    <title>{companyName.companyName} | {t('Common.resetTranspassword')}</title>
                </Helmet>


                {
                    state.notify.show &&
                    <AlertMessage
                        type={state.notify.type}
                        message={state.notify.message}
                        show={state.notify.show}
                        dismiss={notifyDissmissed}
                    />

                }
                <div className={`${style.app} pt-5`}>
                    <Container className={style.loginContainer}>
                        < Brand />
                        <Language classname="mb-4 w-100" />
                        <form id="forgot-form">
                            <Forms
                                type="password"
                                name="newpassword"
                                change={onchange}
                                error={state.FormError.newpassword}
                                value={state.newpassword}
                            />
                            <Forms
                                type="password"
                                name="confpassword"
                                change={onchange}
                                error={state.FormError.confpassword}
                                value={state.confpassword}
                                blurhandler={blurhandler}
                                from="reset"
                            />

                            {state.captchaImage &&
                                <>
                                    <React.Fragment>
                                        <Form.Group style={{ textAlign: 'center' }}>
                                            <img src={state.captchaImage} alt="" />
                                        </Form.Group>
                                        <div style={{ textAlign: 'center' }}>

                                            <a style={{ verticalAlign: "middle", fontSize: '15px', float: 'right', cursor: 'pointer' }} onClick={() => reloadCaptcha(state.user_name)}>   {t('Common.changeCaptcha')} </a>

                                        </div>
                                        <Forms
                                            type="text"
                                            name="captcha"
                                            change={onchange}
                                            error={state.FormError.captcha}
                                        />


                                    </React.Fragment>

                                </>

                            }

                            <ResetButton

                                submit={onSubmit}

                            />

                        </form>
                    </Container>
                </div>
            </div>

        </>
    )

}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResetTranPassword));