import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class BoardService{

    key =localStorage.getItem('apiKey')
    async boardview(boardNo='',limit='',page=''){
        const response = await fetch(`${BASE_URL}boardview/board_view?board_no=${boardNo}&page=${page}&limit=${limit}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
    //userboard
    async userBoard(boardNo='',userId=''){
        const response = await fetch(`${BASE_URL}tree/board_view?board=${boardNo}&user_id=${userId}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}