import React from "react";
import AddForm from "./AddForm";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

const Content = (props) => {
  const { t } = useTranslation();
  return (
    <Container className={`p-3`} style={{display:'flex',justifyContent:'center'}}>
      <AddForm />
    </Container>
  );
};

export default Content;
