import React, { useEffect } from 'react';
import style from './replica.module.scss';
import { Row, Col,Container,Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';



function Footer(props) {


    return (
    
        <footer className="footer_sec">
        <div className="container">
            <div className="row">
                <div className="col-md-6 footer_lft">
                    2022 © {props.data.header_data?.company_name}
                </div>
                <div className="col-md-6 fooer_link">
                <Link to={`/privacy-policy/${props.user_name}`}>Privacy Policy</Link>
                <Link to={`/terms-conditions/${props.user_name}`}>Terms & Conditions</Link>
              
                </div>
            </div>
        </div>
    </footer>

      
    )
    }

export default Footer