import React from 'react';
import { CustumBtnGroup } from '../common';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import style from './donation.module.scss';

function Buttons() {
    const history = useHistory();
    const {t} = useTranslation();
    const backButtonClick = () =>{
        history.goBack();
    }
    //print report button click
    const printReport = () =>{
        var myPrintContent = document.getElementById('divcontents');
        var myPrintWindow = window.open( "Print Report", '_blank', 'left=300,top=100,width=700,height=500',false);
        myPrintWindow.document.write(myPrintContent.innerHTML);
        myPrintWindow.document.close();
        myPrintWindow.focus();
        myPrintWindow.print();
        myPrintWindow.close();
    }
    return (
        <div className={style.BackButtons}>
            <CustumBtnGroup 
                variant="info" 
                BtnProps={{
                    onClick:backButtonClick
                }} >
                <i className="fa fa-backward"></i>
                {t('Button.back')}
            </CustumBtnGroup>
            <CustumBtnGroup 
                variant="info" 
                BtnProps={{
                    onClick:printReport
                }}
                >
                <i className="fa fa-print"></i>
                {t('Button.print')}
            </CustumBtnGroup>
        </div>
    )
}

export default Buttons
