import {extendObservable} from 'mobx';

class UserStore {
    constructor(){
        extendObservable(this,{
            key : '' ,
        });
        let res =JSON.parse(localStorage.getItem('login'))
        if (res) {
            this.key=res.token;
        }
    }
}

export default new UserStore(); 