import React from 'react';
import {Button} from 'react-bootstrap';
import style from './mail.module.scss';
import { useSelector } from "react-redux";

function ContentHeader(props) {
    const { currentTheme } = useSelector((state) => state.Theme);
    return (
        <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_headerContent}`
              : `${style.headerContent}`
          }`}>
            <div className={`${style.btnGroup}`}>
                {
                    !props.view?
                        <Button onClick={props.refersh}>
                            <i className='fa fa-refresh'></i>
                        </Button>:
                        <Button onClick={props.back}>
                            <i className='fa fa-long-arrow-left'></i>
                        </Button>
                }
            </div>
        </div>
    )
}

export default ContentHeader
