import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
//components
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner'
import CrmViewLead from '../../components/crm/CrmViewLead';

//service
import CrmService from '../../../service/crm/Crm';
import AppinfoService from '../../../service/common/Appinfo';
import { useHistory, useParams, withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';

function ViewLead({ t }) {

    let params = useParams()
 
    let history = useHistory()
    const service = new CrmService();
    const appservice = new AppinfoService();

    const [state, setState] = useState({
        loading: true,
        buttonloader:false,
        data: [],
        country: [],
        modal: {
            show: false,
            header: ''
        },
        notify: {
            show: false,
            message: '',
            type: '',
            header: ''
        },
        FormData: {
            user_name: '',
            followup_added_date_from: '',
            followup_added_date_to: '',
            followup_date_from: '',
            followup_date_to: '',
            interest_status: 'All',
            country: '',
            lead_status: params.status?params.status:'All',
            status_date_from: '',
            status_date_to: '',
            formError: {
                user_name: '',
                followup_added_date_from: '',
                followup_added_date_to: '',
                followup_date_from: '',
                followup_date_to: '',
                interest_status: '',
                country: '',
                lead_status: '',
                status_date_from: '',
                status_date_to: '',
            }

        }

    })

    useEffect(async () => {
        const status = params.status;

        if (status) {
        //   await  setState(prevState => ({     
        //         ...prevState,
        //         FormData: {
        //             ...prevState.FormData,
        //             lead_status: 'Ongoing'
        //         }
        //     }));

        }
        LeadData();
        GetCountry();

    }, []);
    useEffect(() => {
       

    }, [state.data]);



    const LeadData = () => {

        const { FormData } = state;
        service.ViewLead(FormData).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    data: res.data
                }));
            } else {
                history.push('/logout');
            }
        })
    }
    const GetCountry = () => {
        appservice.getCountry().then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    country: res.data
                }));
            } else {
                history.push('/logout');
            }
        })
    }

    const changeHandler = (e, keyValue = 'FormData') => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [keyValue]: {
                ...state[keyValue],
                [name]: value,
                formError: {
                    [name]: ""
                }
            }
        }));
      
    }
    const datechange = (date, id, keyValue = 'FormData') => {
        setState(prevState => ({
            ...prevState,
            [keyValue]: {
                ...state[keyValue],
                [id]: date,
                formError: {
                    [id]: ""
                },
            }
        }));

    }
    const onSubmitForm = (e, keyValue = 'FormData') => {
        e.preventDefault();
        const formData = JSON.parse(JSON.stringify(state[keyValue]));
        setState(prevState => ({
            ...prevState,
            buttonloader: true,
        }));
        if (keyValue === 'FormData') {
            delete formData.formError;
            formData.followup_added_date_from = formData.followup_added_date_from && moment(formData.followup_added_date_from).format('yyyy-MM-DD');
            formData.followup_added_date_to = formData.followup_added_date_to && moment(formData.followup_added_date_to).format('yyyy-MM-DD');
            formData.followup_date_from = formData.followup_date_from && moment(formData.followup_date_from).format('yyyy-MM-DD');
            formData.followup_date_to = formData.followup_date_to && moment(formData.followup_date_to).format('yyyy-MM-DD');
            formData.status_date_from = formData.status_date_from && moment(formData.status_date_from).format('yyyy-MM-DD');
            formData.status_date_to = formData.status_date_to && moment(formData.status_date_to).format('yyyy-MM-DD');
        }
        keyValue === 'FormData' &&
            service.ViewLead(formData).then((res) => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: res.data,
                        buttonloader: false,
                    }));
                } else {
                    setState(prevState => ({
                        ...prevState,
                        buttonloader: false,
                    }));

                    if (res.error.code === 1002) {
                        history.push('/logout');
                    } else if (res.error.code === 1057) {
                        history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        });
                    }
                }
            })


    }
    const handleClose = () => {
       
        setState(prevState => ({
            ...prevState,
            modal: {
                show: false
            }
        }))
    }

    const showModal = (type, id) => {
        let lead = state.data.leads.find(lead => lead.id === id);
        lead = {
            first_name: lead.first_name,
            last_name: lead.last_name,
            email_id: lead.email_id === 'NA' ? "" : lead.email_id,
            skype_id: lead.skype_id === 'NA' ? '' : lead.skype_id,
            mobile_no: lead.mobile_no === 'NA' ? '' : lead.mobile_no,
            country: lead.country === 'NA' ? '' : lead.country,
            description: lead.description,
            followup_date: lead.followup_date,
            lead_status: lead.lead_status,
            interest_status: lead.interest_status
        }
        setState(prevState => ({
            ...prevState,
            modal: {
                title: type,
                show: true,
                id: id,
                data: lead
            }
        }))
    }
    const editCallback = () => {

        setState(prevState => ({
            ...prevState,
            modal: {
                title: '',
                show: false,
                id: '',
                data: ''
            },
            notify: {
                show: true,
                message: t('validation.leadUpdatedSuccessfully'),
                type: 'success',
                header: ''
            }
        }));
        LeadData();
    }
    const notifyDissmissed = () => {
        setState(prevState => ({
            ...prevState,
            notify: {
                show: false,
                message: '',
                type: '',
                header: ''
            }
        }))
    }
    if (state.loading) {
        return <Spinner />
    }
    else {
        return (

            <>
                <div className="h-100">
                    <Helmet>
                        <title>{companyName.companyName} | {t('Sidemenu.viewLead')}</title>
                    </Helmet>
                    <PageTitle title="viewLead" buttonOn={false} />
                    <CrmViewLead
                        {...state}
                        chnageHandler={changeHandler}
                        datechange={datechange}
                        onSubmitForm={onSubmitForm}
                        handleClose={handleClose}
                        showModal={showModal}
                        submitCallback={editCallback}
                        notifyDissmissed={notifyDissmissed}
                        LeadData={LeadData}
                    />
                </div>


            </>
        )
    }


}
export default withTranslation()((withRouter(ViewLead)));