import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { AlertBs, CustomButton, FormInputControl } from "../../common";
import style from "../tools.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function TextInvite(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const copyContent = (id) => {
    var tempInput = document.getElementById(id);
    tempInput.select();
    var textField = document.createElement("textarea");
    textField.innerText = tempInput.textContent;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setState((prev) => ({
      ...prev,
      show: true,
    }));
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        show: false,
      }));
    }, 3000);
  };
  const [state, setState] = useState({
    show: false,
    varient: "info",
    message: "validation.textInviteUrlCopied",
  });
  return (
    <Card>
      <Card.Body className={style.cardBody}>
        <AlertBs {...state} />
        {props.inviteText.map((text, index) => (
          <div className={style.textinviteContent} key={index}>
            <div>
              <h4 className="text-center">{text.subject}</h4>
              <hr />
              <div>
                <div className={style.formControl}>
                  <label>
                    <i className="fa fa-calendar"></i>&nbsp;
                    {text.uploaded_date}
                  </label>
                  <FormInputControl
                    inputClass={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_textArea}`
                          : `${style.textArea}`
                      }`}
                    inputProps={{
                      as: "textarea",
                      id: "text" + text.id,
                      disabled: true,
                      value: `<a href=${text.replica}> ${text.content} </a>`,
                    }}
                  />
                </div>
              </div>
              <div>
                <CustomButton
                  variant="primary"
                  onClick={() => copyContent("text" + text.id)}
                >
                  {t("Button.copy")}
                </CustomButton>
              </div>
            </div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
}

export default TextInvite;
