import React, { useEffect, useState } from "react";
import style from "./package_detail.module.scss";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import FormInputGroup from "../common/FormInputGroup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IMG_URL } from "../../../configuration/apiconfig";
const PackageDetails = (props) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState({
    quantity: 0,
    total: 0,
    totalPV: "",
    description: "",
    error: {
      quantity: "",
      total: "",
      totalPV: "",
      description: "",
    },
    isUpdate: false,
  });
  //use Effect
  useEffect(() => {
    let Totalamount = 0;
    let Totalpv = 0;
    let quantity = 0;
    let update = false;
    if (props.cart && props.cart.quantity > 0) {
      Totalamount = props.cart.quantity * props.product.amount;
      Totalpv = props.cart.quantity * props.product.pair_value;
      quantity = props.cart.quantity;
      update = true;
    }
    setState((prev) => ({
      ...prev,
      description: props.product.description,
      total: Totalamount,
      totalPV: Totalpv,
      quantity: quantity,
      isUpdate: update,
    }));
  }, [props.product, props.cart]);
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      error: {
        ...prev.error,
        [name]: "",
      },
    }));
  };
  //obBlurAction
  const blurAction = (e) => {
    const { name, value } = e.target;
    if (name === "quantity") {
      if (value <= 0) {
        setState((prev) => ({
          ...prev,
          quantity: value,
          error: {
            ...prev.error,
            quantity: t("validation.mustbeAtleastOne", { field: "quantity" }),
          },
        }));
      } else if (value > 100) {
        setState((prev) => ({
          ...prev,
          error: {
            ...prev.error,
            quantity: t("validation.valueLessthanOrEquelto", { count: "100" }),
          },
        }));
      } else {
        let Totalamount = value * props.product.amount;
        let Totalpv = value * props.product.pair_value;
        setState((prev) => ({
          ...prev,
          total: Totalamount,
          totalPV: Totalpv,
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const setPrecision = (amount) => {
    let currentAmount = parseInt(amount);
    return currentAmount.toFixed(Currency.precision);
  };
  //click the back button click
  const backButtonClick = () => {
    history.push("/shopping");
  };
  //add to cart
  const addToCart = () => {
    props.updateCart(state.quantity);
  };
  return (
    <div className={style.container}>
      <Row>
        <Col sm={6} md={12} lg={12}>
          <Card>
            <Card.Body className={`${style.panelBody}`}>
              <legend>
                <span className="fieldset-legend">
                  {props.product.product_name}
                </span>
              </legend>
              <Form>
                <Row>
                  <Col md={3}>
                    <img
                      alt=""
                      className={style.width100}
                      src={`${IMG_URL}/product_img/cart.jpg`}
                    />
                  </Col>
                  <Col md={9}>
                    <div className={style.displayFlex}>
                      <div>
                        <span>{t("Common.productId")}: </span>
                        <div className={style.infoLabel}>
                          {props.product.prod_id}
                        </div>
                      </div>

                      <div>
                        <span>{t("Common.price")}: </span>
                        <div className={style.infoLabel}>
                          {`${Currency.currentCurr} ${setPrecision(
                            props.product.amount
                          )}`}
                        </div>
                      </div>

                      <div>
                        <span>{t("Common.PV")}: </span>
                        <div className={style.infoLabel}>
                          {props.product.pair_value}
                        </div>
                      </div>

                      <div>
                        <span>{t("Common.category")}: </span>
                        <div className={style.infoLabel}>
                          {props.product.category}
                        </div>
                      </div>
                    </div>

                    <FormInputGroup
                      isRequired={false}
                      groupId="quantity"
                      label={"Common.quantity"}
                      error={state.error.quantity}
                      inputProps={{
                        onBlur: blurAction,
                        onChange: onChange,
                        type: "number",
                        disabled: false,
                        placeholder: t("Common.quantity"),
                        value: state.quantity,
                        name: "quantity",
                      }}
                    />

                    <FormInputGroup
                      isRequired={false}
                      label={"Common.total"}
                      error={state.error.total}
                      isPrepend={true}
                      inputProps={{
                        onChange: onChange,
                        type: "number",
                        disabled: true,
                        placeholder: t("Common.total"),
                        value: state.total,
                        name: "total",
                      }}
                    />

                    <FormInputGroup
                      isRequired={false}
                      label={"Common.totalPv"}
                      error={state.error.totalPv}
                      inputProps={{
                        onChange: onChange,
                        type: "number",
                        disabled: true,
                        placeholder: t("Common.totalPv"),
                        value: state.totalPV,
                        name: "totalPv",
                      }}
                    />

                    <FormInputGroup
                      isRequired={false}
                      label={"Common.description"}
                      error={state.error.description}
                      inputProps={{
                        onChange: onChange,
                        type: "text",
                        disabled: true,
                        placeholder: t("Common.description"),
                        value: state.description,
                        name: "description",
                      }}
                    />

                    <Form.Group>
                      <Button
                        className={`mr-1 ${style.cartButton}`}
                        disabled={state.quantity <= 0}
                        onClick={addToCart}
                      >
                        {state.isUpdate
                          ? t("Button.updateCart")
                          : t("Button.addToCart")}
                      </Button>
                      <Button
                        className={`${style.cartButton}`}
                        onClick={backButtonClick}
                      >
                        {t("Button.cancel")}
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PackageDetails;
