import React from 'react';
//css
import { ProgressBar, Table} from 'react-bootstrap';
import style from './crm.module.scss';
//service
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import { useSelector } from "react-redux";
function LeadsTable(props) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const {t}  = useTranslation();
    const history = useHistory();
    const ViewLeadClick  = id =>{
        history.push(`/crm/timeline/${id}`)
    }
    return (
        <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
              : `${style.panel} ${style.papanel_defaultnel}`
          }`}>
            <div className={`${style.panel_body} p-0`}>
            {/* <legend><span className={`${style.fieldset_legend}`}>{props.title}</span></legend> */}
                <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_table_responsive} ${style.dark_mode_panel} ${style.papanel_defaultnel}`
              : `${style.table_responsive} ${style.panel} ${style.papanel_defaultnel}`
          }`}>
                        <Table className={`table`}  >
                            <thead>
                                <tr> 
                                    <th>#</th>
                                    <th>{t('Common.fullName')}</th>
                                    <th>{t('Crm.leadCompleteness')}</th>
                                    <th>{t('Common.email')}</th>
                                    <th>{t('Crm.editLead')}</th>
                                    <th>{t('Crm.viewLead')}</th>
                                </tr>
                            </thead>
                        <tbody>
                        { 
                            props.leads&&props.leads.length >0 ? 
                                props.leads&&props.leads.map((item, key) => {
                                    return (
                                        <tr key={key}>             
                                            <td>{key+1}</td>
                                            <td>{`${item.first_name} ${item.last_name}`}</td>
                                            <td>

                                                <ProgressBar active="true" striped  animated  variant={item.lead_completeness>0 && item.lead_completeness<=40?'info':item.lead_completeness>=41&&item.lead_completeness<=80?'warning':item.lead_completeness>=81&&item.lead_completeness<=90?'danger':'success'} label={`${item.lead_completeness}%`} now={item.lead_completeness}  className={item.lead_completeness>50 ? 'progress-bar' : null} className={`${style.progress}`} key={1} />
                                            </td>
                                            <td>{item.email_id}</td>
                                            <td><i onClick={()=>props.showModal('edit',item.id)}  className={` ${style.text_info} fa fa-edit`}></i></td>
                                            <td>
                                                <i className={`${style.text_primary} fa fa-book`} onClick={()=>ViewLeadClick(item.id)}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="11" align="center">
                                        {t('Common.noDataFound')}
                                    </td>
                                </tr>
                        }
                        </tbody>
                        </Table>
                </div>
            </div>
        </div>
            );
}
export default LeadsTable