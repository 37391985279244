let theme = {
    currentTheme:localStorage.getItem("theme")?localStorage.getItem("theme"):"theme-light",
  };
  
  
  const changeThemeReducer = (state = theme, action) => {
    switch (action.type) {
      case "CHANGE_THEME":
        localStorage.setItem("theme",action.theme);
        return { ...state, currentTheme: action.theme };
      default:
        return state;
    }
  };
  
  export default changeThemeReducer;
  