let Menu = {
    dashboard   : {
        title: "Dashboard",
        to: "/dashboard",
        icon: 'fa fa-dashcube',
        substatus : false
    },
    Menu        : [],
    logout      : {
        title: "logout",
        to: "/logout",
        icon: 'fa fa-sign-out',
        substatus : false
    },
    CurrentMenu : localStorage.getItem('path')?localStorage.getItem('path'):''
}
const changeMenuReducer = (state =  Menu , action)=>{
    switch (action.type) {
        case 'ADD_MENU' :
            return {...state,Menu : action.menu }
        case 'CHANGE_MENU' :
            return {...state,CurrentMenu : action.menu }
        default:
            return state;
    }
}

export default changeMenuReducer;