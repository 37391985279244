import React from 'react';
import style from './dashboard.module.scss';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
function Links({iscopy,icon,link}) {
    const {t} = useTranslation();
    function shareButton(url){
        window.open(url,'_blank','height=450,width=550');
    }
    const popover = (
        <Popover id="popover-basic" varient="success">
          <Popover.Title as="h3" >{t('Dashboard.linkCopied')}</Popover.Title>
        </Popover>
      );
    function Clipboard_CopyTo(value) {
        var tempInput = document.createElement("input");
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }
      
    return (
        <>
            {
                iscopy?
                <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                    <a  onClick={()=>Clipboard_CopyTo(link)} ><div className={style.profilesoc_share_bx_ico}><i className={icon}></i></div></a>
                    {/* <Button onClick={()=>Clipboard_CopyTo(link)} className={style.profilesoc_share_bx_ico}><i className={icon}></i></Button> */}
                </OverlayTrigger>
                :
                <a onClick={ () =>shareButton(link)} className={style.profilesoc_share_bx_ico} >
                  
                    <i className={icon}></i>
                </a>
            }
        </>
    )
}

export default Links
