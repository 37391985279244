
// //Local connect 
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.12.60/giovanni/backoffice/api/";
// const IMG_URL = "http://192.168.12.60/giovanni/uploads/images/";
// const BASE = "http://192.168.12.60/giovanni";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='83ea54c9-4ebb-45ca-9dd6-861b6c3cdd15'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

//Demo connect 
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo2.infinitemlmdemo.com/giovanni/backoffice/api/";
// const IMG_URL = "https://demo2.infinitemlmdemo.com/giovanni/uploads/images/";
// const BASE = "https://demo2.infinitemlmdemo.com/giovanni";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='83ea54c9-4ebb-45ca-9dd6-861b6c3cdd15'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }


// Live connect
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.netbigg.it/backoffice/api/";
const IMG_URL = "https://admin.netbigg.it/uploads/images/";
const BASE = "https://admin.netbigg.it/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY ='83ea54c9-4ebb-45ca-9dd6-861b6c3cdd15'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}




