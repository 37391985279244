import React, { useState,useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useHistory, withRouter,useParams } from 'react-router';

//components
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import CrmTimelineContent from '../../components/crm/CrmTimelineContent';
import Spinner from '../../shared/Spinner'
import CrmService from '../../../service/crm/Crm';

function CrmTimeLine({t}) {
    let service = new CrmService()
    let history = useHistory()
    let params = useParams()
    const [state, setState] = useState({
        loading: true,
        display: false,
        leadDetails: {},
        color: '',
        lead_completeness: 0,
        followup_history: [],
        id: '',
        modal: {
            show: false,
            title: ''
        },
        notify: {
            show: false,
            message: '',
            type: ''
        }

    })
  
    useEffect(() => { 
        const {id} = params;
        if(id){
            service.TimeLine(id).then(res=>{

                if(res.status){
                    setState(prevState => ({
                        ...prevState,
                        loading : false,
                        id : id,
                        display : res.data.display,
                        leadDetails : res.data.lead_details,
                        color : res.data.color,
                        lead_completeness : res.data.lead_completeness,
                        followup_history : res.data.followup_history
                    }));
                }else{
                    if(res.error.code === 1002){
                        history.push('/logout')
                    }else if(res.error.code === 1061){
                        history.push({
                            pathname : '/viewLead',
                            state : {
                                error : true,
                                message:'validation.permissionDenied'
                            }
                        })
                    }
                }
            })
        }else{
            history.push({
                pathname : '/viewLead',
                state : {
                    error : true,
                    message:'validation.permissionDenied'
                }
            })
        }

     },[state.modal]);
    const  handleClose = () =>{

        setState(prevState => ({
            ...prevState,
            modal : {
                show : false,
                header : ''
            }
        }))
    }

  const showModal = (id=state.id,type)=>{
        let data = {}
        if(type === 'edit'){
            let lead = {
                first_name : state.leadDetails.first_name,
                last_name : state.leadDetails.last_name=== 'NA' ? "" : state.leadDetails.last_name,
                email_id : state.leadDetails.email_id === 'NA' ? "" : state.leadDetails.email_id,
                skype_id : state.leadDetails.skype_id === 'NA'?'':state.leadDetails.skype_id,
                mobile_no : state.leadDetails.mobile_no === 'NA'?'':state.leadDetails.mobile_no,
                country : state.leadDetails.country === 'NA' ?'':state.leadDetails.country,
                description : state.leadDetails.description,
                followup_date : state.leadDetails.followup_date,
                lead_status : state.leadDetails.lead_status,
                interest_status : state.leadDetails.interest_status
            }
            data = lead
        }
        setState(prevState => ({
            ...prevState,
            modal : {
                id:state.id,
                show : true,
                title : type,
                data : data
            }
        }))
    }

const successCallback = ()=>{

    setState(prevState => ({
        ...prevState,
            modal : {
                show : false,
            },
            notify : {
                show : true,
                message :t('validation.followupAddedSuccessfully') ,
                type : 'success'
            }
        }));
        // this.componentDidMount();
    }
    if(state.loading){
        return <Spinner />
    }
    return (


        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.timeLine')}</title>
            </Helmet>
            <PageTitle title="timeLine" buttonOn={false} />
            <CrmTimelineContent
                {...state}
                showModal={showModal}
                handleClose ={handleClose}
                callback={successCallback}
            />
        </div>
    )



}
export default withTranslation()((withRouter(CrmTimeLine)));