import React from 'react';
import {useTranslation} from 'react-i18next';
import style from './registration.module.scss';
function Paneltilte(props) {
    const {t} = useTranslation();
    return (
        <div className={style.heading}>
            <h5>{t('registration.title.'+props.tilte)}</h5>
        </div>
    )
}

export default Paneltilte
