import React, { useState, useEffect } from "react";
import style from "./profile.module.scss";
import { FormHead, FormsControl, Buttons } from "./";
import moment from "moment";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function PersonalDetials(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState(props.data);
  const [cancelled, setCancelled] = useState(false);
  const [adult, setAdult] = useState();
  const service = new ProfileService();
  const history = useHistory();
  //life cycle
  useEffect(() => {
    let newData = props.data.map((object) => {
      if (object.type === "select") {
        let newOption = object.options.map((option) => {
          return {
            ...option,
            code:
              option.value === "M" ? t("profile.male") : t("profile.female"),
          };
        });
        return {
          ...object,
          options: newOption,
          value: object.value ?? "M",
        };
      } else {
        return object;
      }
    });
    setData(newData);
    setCancelled(false);
  }, [props.data, cancelled, props.isEditable, t]);
  const changeHandler = (e, isReq) => {
    const { name, value } = e.target;
    setData(
      [...data].map((object) => {
        if (object.code === name) {
          return {
            ...object,
            value: value,
            error: value.length === 0 && isReq ? "validation.required" : "",
            errorField: {
              field: name,
            },
          };
        } else return object;
      })
    );
  };

  //change the dropdown items
  const changeDropDown = (key, id) => {
    setData(
      [...data].map((object) => {
        if (object.code === id.code) {
          return {
            ...object,
            selected: key === "M" ? t("profile.male") : t("profile.female"),
            value: key,
          };
        } else return object;
      })
    );
  };
  //click the cancel button click
  const cancelButtonClick = () => {
    setCancelled(true);
    props.cancelbtn();
  };

  //date select change
  const selectDate = (selectedDate, id) => {
    var dob = new Date(selectedDate);
    var now = new Date();
    if (now - dob > 568024668000) {
      setAdult(true);
    } else {
      setAdult(false);
    }

    setData(
      [...data].map((object) => {
        if (object.code === id) {
          return {
            ...object,
            value: moment(selectedDate).format("yyyy-MM-DD"),
            error: adult == true ? "" : "validation.not18",
            errorField: {
              field: id,
            },
          };
        } else return object;
      })
    );
  };

  const onSubmit = () => {
    let error = false;
    let formdata = {};
    data.forEach((element) => {
      if (element.error) {
        error = true;
      } else {
        formdata = {
          ...formdata,
          [element.code]: element.value,
        };
      }
    });
    if (!error) {
      service.UpdatePersonalDetails(formdata).then((res) => {
        if (res.status) {
          //if successfully completed
          if (res.data.status) {
            props.getData();
            props.showNotify(
              "success",
              "success",
              "profile." + res.data.message
            );
            props.cancelbtn();
            props.updateSuccess(data, props.stateKey);
          }
        } else {
          if (res.error.code === 1004) {
            props.showNotify(
              "danger",
              "error",
              "validation.checkValueYouHaveSubmitted"
            );
            for (const property in res.error.fields) {
              setData(
                [...data].map((object) => {
                  if (object.code === property) {
                    return {
                      ...object,
                      error:
                        "validation." + res.error.fields[property + "_err"],
                      errorField: {
                        field: property,
                        length:
                          res.error.fields[property + "_err"] === "min_length"
                            ? 3
                            : 32,
                      },
                    };
                  } else return object;
                })
              );
              return null;
            }
          } else if (res.error.code === 1002) {
            history.push("/logout");
          }
        }
      });
    } else {
      props.showNotify(
        "danger",
        "error",
        "validation.checkValueYouHaveSubmitted"
      );
    }
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_PersonalDetials}`
          : `${style.PersonalDetials}`
      }`}
    >
      <FormHead
        title="personalDetails"
        change={props.change}
        status={props.status}
        show={props.isEditable}
      />
      {data.map((forms, index) => {
        if (forms.code != "dateOfBirth") {
          return (
            <FormsControl
              key={index}
              change={changeHandler}
              status={props.isEditable}
              data={forms}
              dropdownChange={changeDropDown}
              dateChange={selectDate}
            />
          );
        }
      })}
      {!props.isEditable && (
        <Buttons
          cancel={() => {
            cancelButtonClick();
          }}
          submitHandler={onSubmit}
        />
      )}
    </div>
  );
}

export default PersonalDetials;
