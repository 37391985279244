import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';
export default class MailBoxService{
    key =localStorage.getItem('apiKey')
    //inbox mail list get 
    async inboxMail(start,limit){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/inbox_mail_list?offset='+start+'&limit='+limit, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //send mail list get 
    async sendMail(start,limit){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/sent_mail_list?offset='+start+'&limit='+limit, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //view inbox mail list 
    async viewInbox(id,type,thread){
       
      
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/inbox_mail?msg_id='+id+'&msg_type='+type+'&thread='+thread, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    //view inbox mail list 
    async viewsendbox(id,type){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/sent_mail?id='+id+'&type='+type, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    //delete mail
    async   deleteMail(deleteData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/mail_delete', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(deleteData)
                })
                .then((response) => {
                    if(response.status === 204){
                        if(response.status===204){
                            return {
                                status : true
                            }
                        }
                        return response.json();
                    }
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //reply message
    async getReplyData(id){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/mail_reply_data?mail_id='+id, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //compose mail data
    async composeData(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'mail/mail_compose', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body : JSON.stringify(data)
                })
                .then((response) => {
                    if(response.status === 204){
                        return {
                            status : true
                        }
                    }
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    // get the compose mail data
    async getcomposeData(){
        const response = await fetch(BASE_URL+'mail/mail_compose_data', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
    //mail reply
    async mailReply(data){
        const response = await fetch(BASE_URL+'mail/mail_reply', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body : JSON.stringify(data)
        })
        if(response.status===204){
            return{
                status:true
            }
        }
        return await response.json();
    }
}