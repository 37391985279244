const Mail = {
    count : 0,
    mail_data : []
};
const MailReducer = (state = Mail, action)=>{
     switch (action.type) {
         case 'Add_Mail':
             return {count : action.count,mail_data : action.data};
         default:
             return state;
     }
 }

 export default MailReducer;