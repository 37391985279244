import React from 'react';
import {Modal}from 'react-bootstrap';
import style from './crm.module.scss';
const CrmModal = ({children,...props}) => {
    return ( 
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton className={`${style.ModalHeader}`}>
            <Modal.Title className={`text-center`}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`p-0`}>
                {children}
            </Modal.Body>
        </Modal>
     );
}
 
export default CrmModal;