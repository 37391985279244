import React from 'react';
import style from './auth.module.scss';
import {useTranslation} from 'react-i18next';
const ForgotButton = ({submit,...props}) => {
    const {t} = useTranslation();
    return (
        <div className={style.loginButton}>
            <button className={`${style.loginFormBtn}`} onClick={submit} disabled={props.disabled}>{t('Login.PlaceHolder.sendreq')}</button>
        </div>
    );
};

export default ForgotButton;