import React from 'react';
import {useTranslation} from 'react-i18next';
import { Multiselect } from "multiselect-react-dropdown";

function Filter(props) {
    const {t} = useTranslation();
    // multi select css components
    let styles = {
        multiselectContainer: { // To change css for multiselect (Width,height,etc..)
            minWidth: "10%",
            maxWidth: "max-content",
            display : "inline-block"
          },
        inputField: { // To change input field position or margin
            margin: "5px",
        },
        chips: { // To change css chips(Selected options)
          background : "rgb(228, 228, 228)",
          color : "rgb(88, 102, 110)",
          borderRadius : "10px",
          height : "20px"
        },
        optionContainer: { // To change css for option container 
            boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
            border : "none"
        },
        searchBox: { // To change search box element look
            padding:0,
            marginRight : "5px",
            paddingLeft : "5px"
          },
        option: { // To change css for dropdown options
          color: "black" 
        },
      }
    return (
        <Multiselect
            singleSelect={false}
            options={props.option}
            className="col-md-3 col-sm-12"
            showCheckbox={true}
            style={styles}
            onSelect={(e)=>{
              props.handler(e,props.placeHolder)
            }}
            displayValue={props.displayKey}
            avoidHighlightFirstOption={true}
            placeholder={t('Common.'+props.placeHolder)}
            selectedValues={props.selectedValue}
            id={props.placeHolder}
            onSearch={(e)=>{
              if(props.search && e){
                props.search(e)
              }
            }}
            emptyRecordMsg={t('Common.optionNotAvailable')}
        />
        
    )
}

export default Filter
