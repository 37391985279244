import React from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
function DataTables(props) {
   
    
    
   const { t } = useTranslation()
    return (
        <DataTable 
       
                columns={props.columns}
                data={props.data}
                highlightOnHover
                // progressPending={props.pending}
                 //progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                responsive
                paginationTotalRows={props.totalRows}
                onChangeRowsPerPage={props.handlePerRowsChange}
                onChangePage={props.handlePageChange}
                noDataComponent={t('Common.noDataRecordsToDisplay')}
                // onSort={handleOnSort}
                />
    )
}

export default DataTables 
