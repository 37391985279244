import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import style from "./tools.module.scss";
import NewsService from "../../../service/tools/news";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";

const NewsContent = () => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState({
    data: [],
    loader: false,
  });
  useEffect(() => {
    const service = new NewsService();
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    service.getNews().then((res) => {
      if (res.status) {
        setState({
          data: res.data.news_data,
          loader: false,
        });
      } else {
      }
    });
  }, []);

  const goToView = (news_id) => {
    history.push(`/news/${news_id}`);
  };
  return state.loader ? (
    <Spinner />
  ) : (
    <div className={style.newsContent2}>
      <div className={style.totalview}>
        {state.data &&
          state.data.map((items, index) => (
            <div key={index}>
              <Card className={style.newscaard}>
                <Card.Body>
                  <div className={style.details}>
                    <div className={style.image}>
                      <img
                        src={items.news_image}
                        className={style.news_imgview}
                        alt=""
                      />
                    </div>
                    <div className={style.detailsData}>
                      <div>
                        <Link
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_link}`
                              : `${style.link}`
                          }`}
                          to={"/news/" + items.news_id}
                        >
                          {items.news_title}
                        </Link>
                        <div>
                          <i className="fa fa-clock-o text-muted"></i>
                          {" " + moment(items.news_date).format("MM/DD/yyyy")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Button
                      className={style.Button}
                      onClick={() => goToView(items.news_id)}
                    >
                      {/* <Link to={"/news/"+items.news_id} className={style.readMore}> */}
                      {/* {t('Button.readMore')} */}
                      {/* </Link> */}
                      {t("Button.readMore")}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
};

export default NewsContent;
