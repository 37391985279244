import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class PartySetupService{
    key =localStorage.getItem('apiKey')
    async getTheHost(){
        const response = await fetch(`${BASE_URL}party/create_setup`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
    async getCountry(){
        const response = await fetch(`${BASE_URL}party/getAllCountry`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
    async countryChange(countryID){
        const response = await fetch(`${BASE_URL}profile/countryChange?country_id=${countryID}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}