import React, { useEffect, useState } from 'react';
import style from './tree.module.scss';
import {TitleHeaders} from './';
import {useTranslation} from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {Table} from 'react-bootstrap';
import DownLineService from '../../../service/tree/downLine';
import ReactPaginate from 'react-paginate';
import Spinner from '../../shared/Spinner';
import { IMG_URL } from '../../../configuration/apiconfig';
const DownLineContent = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const [state,setState] = useState({
        level : 'all',
        offset : 0,
        data : [],
        count : 0,
        totalLevels : [],
        showNext : true,
        loading:true,
        searchloader:false,
        page_count:0

    })
    
    useEffect(()=>{
        const service = new DownLineService();

        service.getDownline(state.level,state.offset).then(res=>{
            if(res.status){
                setState(prev=>({
                    ...prev,
                    data : res.data.tableData,
                    totalLevels : new Array(res.data.total_levels).fill().map((_, idx) => idx+1),
                    count : res.data.total_downline_count,
                    loading:false,
                    // level : res.data.total_levels
                }))
            }else{
                history.push('/login');
            }
        })
    
    },[state.level,state.offset,history]);

    const goToPreviousPath = () => {
        history.goBack()
    }


   //onreset button click 
   const reset =()=>{
       setState(prev=>({
           ...prev,
           level : '',
           offset: 0
       }))
   }
    //serch the level wise list 
    const searchLevel = (level)=>{
        setState(prev=>({
            ...prev,
            searchloader:true
        }));

        setTimeout(() => {
  
            setState(prev=>({
                ...prev,
                level:level,
                searchloader:false
            }));

          }, 3000);
      
    }
    const handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 20);
        let showNext = true
        if(offset>0){
            showNext = Math.floor(state.count/offset)===1?false:true
        }
        setState(prev=>({
            ...prev,
            offset : offset,
            showNext  :showNext
        }));
      };
    return (  
        <div className={style.DownlineMembers}>
            {state.loading?
            <Spinner/>
            :
            <>
            { history.location.state&&history.location.state.prevPath=='/genealogyTree'?
            <button className={style.back_btn} onClick={goToPreviousPath}>Back
            <i className=" btn_icon fa fa-backward"></i>
            </button>:(<></>)}
       
            <TitleHeaders 
                tilesvalue="downlineMembers"
                count={state.count}
                level={state.totalLevels}
                searchLevel={searchLevel}
                reset={reset}
                searchloader={state.searchloader}
            />
            <div>
                <Table striped   hover responsive className={style.tableData}>
                    <thead>
                        <tr>
                            <th>{t('Common.memberName')}</th>
                          { state.data[0]?.placement&&    <th>{t('Common.placement')}</th>}
                            <th>{t('Common.sponsor')}</th>
                            <th>{t('Common.level')}</th>
                            <th>{t('Common.action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            state.data.length > 0 ?
                            state.data.map((items,index) =>(
                                <tr key={index}>
                                    <td className={style.tableAvatarDetails}>
                                        <div>
                                            <img src={items.user_photo} alt="" className={style.profileImage}/>
                                            <span className={`${style.active} ${items.active ==='yes'?style.active:style.inactive}`}></span>
                                        </div>
                                        <div>
                                            <div className={style.userName}>{items.user_detail_name} {items.user_detail_second_name}</div>
                                            <span className={style.userid}>{items.user_name}</span>
                                        </div>
                                    </td>
                                  {items.placement&&  <td>{items.placement}</td>}
                                    <td>{items.sponsor}</td>
                                    <td>{items.ref_level}</td>
                                    <td>
                                        <Link to={`/genealogyTree/${items.user_name}`} className={style.action}>

                                        <div className={style.tooltip}>
                                           <i className="fa fa-sitemap">
                                           <span className={style.tooltiptext}>{t('Common.viewgene')}</span>
                                               </i>
                                        </div>
                                           
                                        </Link>
                                    </td>
                                </tr>
                            )):(

                                (
                                    <div className={`${style.NodataImage}`}>
                                        <img src={IMG_URL + "logos/no-datas-found.png"} alt="nodata" style={{ width: '50%' }} />

                                    </div>
                                )
                            )
                        }
                    </tbody>
                </Table>
                { 


                  

                       <ReactPaginate
                                previousLabel={t('<<')}
                                nextLabel={t('>>')}
                                breakLabel={''}
                                 breakClassName={'break-me'}
                                pageCount={Math.ceil(state.count/20)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={style.paginationContainer}
                                activeClassName={style.active}
                                previousLinkClassName={state.offset===0?'d-none':style.Link}
                                nextLinkClassName={!state.showNext?'d-none':style.Link}
                                renderOnZeroPageCount={null}
                            />  
                }
            </div>
            </>
}
        </div>
    );
}
 
export default DownLineContent;