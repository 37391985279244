import React,{useState} from 'react'
import {FormInputControl,CustomButton,BackButton,AlertBs} from '../common';
import style from './tools.module.scss';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';



function InviteContent(props) {



    const {t} = useTranslation();
    return (
        <div className={style.inviteEditContainer}>
            <BackButton/>

            <AlertBs 
                show={props.alert.show}
                varient={props.alert.variant}
                message={props.alert.message}
                close={props.closeAlert}
            /> 
            <Card>
                <Card.Body className={style.cardBody}>

                    {props.field.map(item=>{

                        if(item.fieldname=='to')
                        {
                            return (
                                <>
                                <FormInputControl
                                label={t('Common.to')}
                                required={true}
                                inputProps={{
                                    name:item.fieldname,
                                    type:"text",
                                    value : item.value,
                                    onChange : props.chacngeHandler
                                }}
                                error={item.error}
                            ></FormInputControl>
                            <div>
                            {!item.error &&
                            <span className={style.quots}>
                            {`"${t('Common.youCanentermultipleemailIdsseparatedBycomma')}"`}
                        </span>
                        }
                            </div>
                           
                            </>
                            )
                        }
                        else if(item.fieldname=='subject'){
                            return (
                                <FormInputControl
                                label={t('Common.subject')}
                                required={true}
                                inputProps={{
                                    name:item.fieldname,
                                    type:"text",
                                    value : item.value,
                                    onChange : props.chacngeHandler
                                }}
                                error={item.error}
                            ></FormInputControl>

                            )

                        }
                        else {
                            return (
                                <FormInputControl
                                label={t('Common.message')}
                                required={true}
                                inputProps={{
                                    name:item.fieldname,
                                    type:"text",
                                    value : item.value,
                                    onChange : props.chacngeHandler
                                }}
                                error={item.error}
                            ></FormInputControl>

                            )

                        }

                        
                     


                     
                    })}
                    
                    {/* <FormInputControl
                        label={t('Common.to')}
                        required={true}
                        inputProps={{
                            name:"to",
                            type:"text",
                            value : state.to,
                            onChange : props.chacngeHandler
                        }}
                        error={props.FormError.to}
                    >
                        <span className={style.quots}>
                            {`"${t('Common.youCanentermultipleemailIdsseparatedBycomma')}"`}
                        </span>
                    </FormInputControl> */}
                    {/* <FormInputControl 
                        label={t('Common.subject')}
                        required={true}
                        inputProps={{
                            name:"subject",
                            type:"text",
                            value : props.subject,
                            onChange : props.chacngeHandler
                        }}
                        error={props.FormError.subject}
                    /> */}
                    {/* <FormInputControl 
                        label={t('Common.message')}
                        required={true}
                        inputProps={{
                            name:"message",
                            as:"textarea",
                            value : props.message,
                            onChange : props.chacngeHandler
                        }}
                        error={props.FormError.message}
                    /> */}
                    <CustomButton 
                        variant="primary"
                        onClick={props.formSubmit}
                    >
                        {
                            props.buttonloder?
                            <i className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                        /> : ''}
                        {t('Button.invite')}
                    </CustomButton>
                </Card.Body>
            </Card>
        </div>
    )
}

export default InviteContent;
