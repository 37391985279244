import React from 'react';
import {Row} from 'react-bootstrap';
import style from './board.module.scss';
import { usePopperTooltip } from 'react-popper-tooltip';
import {ToolTips} from '../'
const ChildNode = (props) =>{
    const width = Math.pow(props.width,props.depth)*100;
    let Components = [];
    let Childs= [];
    const inActiveData = {
        user_name : 'NA'
    }
    if(props.currentdepth>=0){
        if(props.children){
            props.children.map((items,index)=>{
                if(items.hasOwnProperty('children')){
                    items.children.map((key) => {
                        Childs.push(key)
                        return true
                    });
                }else{
                    new Array(props.width).fill(inActiveData).map((key) => {
                        Childs.push(key)
                        return true
                    });
                }
                return true
            })
        }else{
            new Array(props.width).fill(inActiveData).map((key) => {
                Childs.push(key)
                return true
            });
        }
        Components.push(<ChildNode
            key={`child-${props.currentdepth}`}
            children={Childs}
            currentdepth={props.currentdepth-1}
            depth={props.depth}
            width={props.width}
            toolTipConfig={props.toolTipConfig}
        />)
        const LevelWidth = Math.pow(props.width,(props.depth-props.currentdepth));
        props.children&&Components.push( <Row 
            className={style.Row} 
            key={props.currentdepth}
            style={{width : `${width}px`}}>
           
            {
                props.children.map((items,index)=>{
                    if(items.user_name === 'NA'){
                        return inActiveNode(width/LevelWidth,1,index);
                    }
                    return ActiveNode(width/LevelWidth,items,props.toolTipConfig)
                })
            }
            {
                props.children&&props.children.length<LevelWidth&&
                inActiveNode(width/LevelWidth,LevelWidth-props.children.length)
            }
        </Row>);
        
    }
    return Components
}
//get the Root Node
const RootNode =(props)=>{
    const width = Math.pow(props.width,props.depth)*100;
    return<Row className={style.Row} style={{width : `${width}px`}}>
        {
            ActiveNode(width,props,props.toolTipConfig)
        }
        </Row>
}
//inactive node
const inActiveNode = (width,length) =>{
    return new Array(length).fill().map((key,index) => {
        return <div className={`${style.TableDiv}`} style={{width : `${width}px`}} key={index}>
                    <div className={`${style.inactive}`}>
                    <span>NA</span>
                    </div>
                </div>
        });
    
}
//active node
const ActiveNode = (width,props,toolTipConfig) =>{
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
      } = usePopperTooltip();
    return (<div className={`${style.TableDiv}`} style={{width : `${width}px`}} key={props.user_name}>
                <div className={`${style.active}`}>
                    <span ref={setTriggerRef}>{props.user_name}</span>
                    {visible && (
                        <div
                        ref={setTooltipRef}
                        {...getTooltipProps({ className: `tooltip-container` })}
                        >
                            <div {...getArrowProps({ className: ` ${style.arrow}` })} />
                                <ToolTips 
                                config={toolTipConfig}
                                    {...props}
                                />
                            </div>
                        )}
                </div>
            </div>)
}

function TableNode(props) {
    return (
        <>
            {
                ChildNode(props)
            }
            {
                RootNode(props)
            }
        </>
    )
}

export default TableNode
