import React from 'react'
import {Row,Col, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import style from './cart.module.scss';
function EmptyCart() {
    const {t} = useTranslation();
    return (
        <Row>
            <Col md={12} sm={12}>
                <h4 className={`text-center mt-2 mb-2`}>{t('Common.yourCartIsEmpty')}</h4>
                <div className={`pull-right mr-5`}>
                    <Link to="/shopping">
                        <Button className={style.continueButton}>
                            <i className="fa fa-shopping-cart"></i>
                            {t('Button.continueShopping')}
                        </Button>
                    </Link>
                </div>
            </Col>
        </Row>
    )
}

export default EmptyCart
