import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class TreeViewService{
    key =localStorage.getItem('apiKey')
    //get the referral members
    async getTreeViewData(username){
        const response = await fetch(BASE_URL+'tree/tree_view?user_name='+username, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}