const Notification = [];
const NotificationReducer = (state = Notification, action)=>{
     switch (action.type) {
         case 'Add_Notificcation':
             return action.notification;
         default:
             return state;
     }
 }

 export default NotificationReducer;