import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  DatePickers,
  FormInputControl,
  CustomButton,
  AlertBs,
} from "../common";
import CrmService from "../../../service/crm/Crm";
import moment from "moment";
import style from "./crm.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const AddFollowUp = (props) => {
    const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();

  const InitialForm = {
    lead_name: "",
    description: "",
    followup_date: "",
    upload_doc: "",
    id: "",
    formError: {
      lead_name: "",
      description: "",
      followup_date: "",
      upload_doc: "",
      id: "",
    },
  };

  const [state, setState] = useState({
    ...InitialForm,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    addfollowploader: false,
  });
  const service = new CrmService();
  useEffect(() => {
    const { id, name } = props;
    if (id) {
      setState((prev) => ({
        ...prev,
        id: id,
      }));
    }
    if (name) {
      setState((prev) => ({
        ...prev,
        lead_name: name,
      }));
    }
  }, [props]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      formError: {
        ...prev.formError,
        [name]: "",
      },
    }));
  };
  const dateChange = (date, id) => {
    let c_date = moment(date).format("yyyy-MM-DD");
    let today = moment(new Date()).format("yyy-MM-DD");
    setState((prev) => ({
      ...prev,
      [id]: c_date,
      formError: {
        ...prev.formError,
        [id]: "",
      },
    }));
  };

  const onFileChange = (e) => {
    // Update the state
    if (e.target.files[0]) {
      let file = e.target.files[0];
      setState((prev) => ({
        ...prev,
        upload_doc: file,
      }));
    }
  };
  //submit the form
  const onSubmitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    let FormError = {};
    //validation in first nanme
    if (!state.lead_name) {
      isValid = false;
      FormError.lead_name = {
        error: "validation.required",
        field: {
          field: "lead_name",
        },
      };
    }
    //validation in discription
    if (!state.description) {
      isValid = false;
      FormError.description = {
        error: "validation.required",
        field: {
          field: "description",
        },
      };
    }
    //validatoion in follow up date
    if (!state.followup_date) {
      isValid = false;
      FormError.followup_date = {
        error: "validation.required",
        field: {
          field: "followup_date",
        },
      };
    }
    if (state.followup_date < moment(new Date()).format("yyy-MM-DD")) {
      isValid = false;
      FormError.followup_date = {
        error: "validation.dategreateThancurrentDate",
        field: {
          field: "followup_date",
        },
      };
    }
    if (isValid) {
      let formData = new FormData();
      Object.keys(state).map((key) => {
        formData.append(key, state[key]);
        return true;
      });
      setState((prev) => ({
        ...prev,
        addfollowploader: true,
      }));
      service.AddFollowup(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              varient: "success",
              message: "validation.followup_added_successfully",
            },
            addfollowploader: false,
          }));
          if (props.callBack) {
            props.callBack();
          }
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          } else if (res.error.code === 1004) {
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                varient: "danger",
                message: "validation.checkValueYouHaveSubmitted",
              },
              addfollowploader: false,
            }));
          }
        }
      });
    } else {
      setState((prev) => ({
        ...prev,
        formError: {
          ...prev.formError,
          ...FormError,
        },
      }));
    }

    // e.preventDefault();
  };
  //close alert
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };
  return (
    <div className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
          : `${style.panel} ${style.papanel_defaultnel}`
      }`}>
      <div className={`${style.panel_body}`}>
        <AlertBs {...state.alert} close={closeAlert} />

        <Form onSubmit={onSubmitForm}>
          <FormInputControl
          inputClass={`${
            currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
          }`}
            label={t("Crm.Lead")}
            inputProps={{
              type: "text",
              name: "lead_name",
              placeholder: t("Crm.Lead"),
              value: state.lead_name,
              onChange: changeHandler,
              disabled: true,
            }}
            error={state.formError.lead_name}
          />
          <FormInputControl
          inputClass={`${
            currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
          }`}
            label={t("Crm.Description")}
            required={true}
            inputProps={{
              name: "description",
              placeholder: t("Crm.Description"),
              value: state.description,
              as: "textarea",
              onChange: changeHandler,
            }}
            error={state.formError.description}
          />
          <DatePickers
            required={true}
            label={t("Crm.NextFollowupDate")}
            start={state.followup_date}
            placeholderText={t("Common.fromDate")}
            datechange={dateChange}
            id="followup_date"
            error={state.formError.followup_date}
          />
          <FormInputControl
            label={t("Common.selectFile")}
            inputProps={{
              type: "file",
              name: "upload_doc",
              onChange: onFileChange,
            }}
          />
          <span className={style.selectfile}>
            Please choose a png/jpeg/jpg/xls/doc file{" "}
          </span>
          <CustomButton type="submit" variant="primary">
            {state.addfollowploader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}
            {t("Button.AddFollowup")}
          </CustomButton>
        </Form>
      </div>
    </div>
  );
};

export default AddFollowUp;
