import React from 'react';
import style from './Common.module.scss';
function CustumBtnGroup({children,...props}) {
    return (
        <button className={`${style.btnGrop} ${style[props.variant]} ${props.btnclass}`} {...props.BtnProps}>
            {children}
        </button>
    )
}

export default CustumBtnGroup
