import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class invoiceService {
    API_KEY=localStorage.getItem('apiKey')

    //view the invoice
    async getInvoice(id){
        const response = await fetch(`${BASE_URL}cart/purchase_invoice?id=${id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        
        const result = await response.json();
        console.log(result)
        return result;
    }

    //get the report 
    async getReport(start=0,length=10,startDate='',endDate=''){
        const response = await fetch(`${BASE_URL}cart/purchaseReport?start=${start}&length=${length}&start_date=${startDate}&end_date=${endDate}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}