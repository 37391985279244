import React from 'react';
import style from './board.module.scss';
import { Card, Col, Row} from 'react-bootstrap';
import {CustomButton ,BackButton} from '../../common';
import { BoardTree } from "./";
import { useState } from 'react';

function TreeContent(props) {
    const [zoom, setZoom] = React.useState(1);
    const [overf, setOverf] = React.useState("auto");

       
    const zoominoutresetfun = (x) => {

        if(x == "add"){                     
                setZoom(zoom + .1)
                if(zoom >= 2)
                {
                    setZoom(2)
                }
             }

        else if(x == "sub"){
            setZoom(zoom - .1)
            if(zoom <= .5)
            {
                setZoom(.4)
            }
        
        }
        else{
            setZoom(1)
        }
    }

    return (
        <div className={style.treeContainer}>
            <div className={style.actionContent}>
                <Card>
                    <Card.Body className={style.panelBody}>
                            <Row>
                                <Col md={3}>
                                    <CustomButton variant="primary"  onClick={()=>zoominoutresetfun("add")}>
                                        <i className="fa fa-search-plus"></i>
                                    </CustomButton>
                                    <CustomButton variant="info" onClick={()=>zoominoutresetfun("sub")}>
                                        <i className="fa fa-search-minus"></i>
                                    </CustomButton>
                                    <CustomButton variant="primary" onClick={()=>zoominoutresetfun("ress")}>
                                        <i className="fa fa-power-off"></i>
                                    </CustomButton>
                                </Col>
                                <Col>
                                    <BackButton/>
                                </Col>
                            </Row>
                    </Card.Body>
                </Card>
            </div>
        
            <BoardTree id="jumba" style={{transform: 'matrix('+zoom+', 0, 0, '+zoom+', 0, 0)',overflow: overf}}
            {...props}
            />
        </div>
    )
}

export default TreeContent
