const Project = {
    mlmPlan : 'Binary',
    userName : ''
};
const ProjectReducer = (state = Project, action)=>{
     switch (action.type) {
        case 'CHANGE_PLAN':
             return {...state,mlmPlan : action.plan};
        case 'ADD_USERNAME' :
            return {...state,userName : action.name}; 
        default:
             return state;
     }
 }

 export default ProjectReducer;