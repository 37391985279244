import React, { useState } from "react";
import { FormHead, Buttons, FormsControl } from "./";
import style from "./profile.module.scss";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function ContactDetails(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [data, setData] = useState(props.data);
  const service = new ProfileService();
  const history = useHistory();

  const changeHandler = (e, isReq) => {
    const { name, value } = e.target;
    setData(
      [...data].map((object) => {
        if (object.code === name) {
          var regexPattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
          if (name == "email" && !regexPattern.test(value)) {
            return {
              ...object,
              value: value,
              error: "validation.invalidEmail",
              errorField: {
                field: name,
              },
            };
          }
          return {
            ...object,
            value: value,
            error: value.length === 0 && isReq ? "validation.required" : "",
            errorField: {
              field: name,
            },
          };
        } else return object;
      })
    );
  };

  const changeDropDown = (key, id) => {
    if (id.code === "country") {
      service.countryChange(key).then((res) => {
        if (res.status) {
          setData(
            [...data].map((object) => {
              if (object.code === "state") {
                return {
                  ...object,
                  options: res.data,
                };
              } else if (object.code === "country") {
                return {
                  ...object,
                  value: key,
                };
              } else if (object.code === "mobile") {
                return {
                  ...object,
                  country_code: res.data[0]?.country_code,
                };
              }
              return object;
            })
          );
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          }
        }
      });
    } else {
      setData(
        [...data].map((object) => {
          if (object.code === id.code) {
            return {
              ...object,
              value: key,
            };
          } else return object;
        })
      );
    }
  };
  //click the cancel button click
  const cancelButtonClick = () => {
    setData(props.data);
    props.cancelbtn();
  };

  const onSubmit = () => {
    let error = false;
    let formdata = {};
    data.forEach((element) => {
      if (element.error) {
        error = true;
      }
      if (element.required && !element.value) {
        element.error = "validation.required";
        element.errorField = {
          field: element.code,
        };
      } else {
        if (element.value === "NA") {
          formdata = {
            ...formdata,
            [element.field_name]: "",
          };
        } else {
          formdata = {
            ...formdata,
            [element.field_name]: element.value ?? "",
          };
        }
      }
    });
    if (!error) {
      service.UpdateContactDetails(formdata).then((res) => {
        if (res.status) {
          props.showNotify("success", "success", "profile." + res.data.message);
          props.cancelbtn();
          props.updateSuccess(data, props.stateKey);
          props.getData();
        } else {
          if (res.error.code === 1004) {
            Object.keys(res.error.fields).map((key) => {
              setData(
                [...data].map((object) => {
                  if (object.field_name === key) {
                    if (key == "email") {
                      return {
                        ...object,
                        error: "validation.invalidEmail",
                        errorField: {
                          field: key,
                        },
                      };
                    }
                    return {
                      ...object,
                      error: "validation." + res.error.fields[key],
                      errorField: {
                        field: key,
                      },
                    };
                  } else return object;
                })
              );
              return null;
            });
            props.showNotify(
              "danger",
              "error",
              "validation.checkValueYouHaveSubmitted"
            );
          } else if (res.error.code === 1002) {
            history.push("/logout");
          }
        }
      });
    } else {
      setData(data);
      props.showNotify(
        "danger",
        "error",
        "validation.checkValueYouHaveSubmitted"
      );
    }
    // props.cancelbtn();
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_ContactDetails}`
          : `${style.ContactDetails}`
      }`}
    >
      <FormHead
        title="contactDetails"
        change={props.change}
        status={props.status}
        show={props.isEditable}
      />
      <div className={style.ContactDetailsForms}>
        {data.map((forms, index) => {
          return (
            <FormsControl
              key={index}
              change={changeHandler}
              status={props.isEditable}
              data={forms}
              dropdownChange={changeDropDown}
            />
          );
        })}
      </div>
      {!props.isEditable && (
        <Buttons cancel={cancelButtonClick} submitHandler={onSubmit} />
      )}
    </div>
  );
}

export default ContactDetails;
