import React from 'react';
import style from './donation.module.scss';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CustomButton,AlertBs} from '../common';
function ViewContent(props) {
    const {t} = useTranslation();
    return (
        props.data &&
        <div className={style.Maincontainer}>
            <AlertBs 
            {...props.alert}
            close={props.closeAlert}
            />
            <Card>
                <Card.Body className={`${style.CardBody}`}>
                    {
                        props.data.error || props.data.top_level?
                        <div>
                            <center >
                                <p style={{color : props.data.top_level?'#3f4454':'#ec564a'}}>
                                    {props.data.message}
                                </p>
                            </center>
                        </div>
                        :
                        <React.Fragment>
                            <div className={`${style.formGroup}`}>
                                <label>{t('Common.upgradeToLevel')}</label>
                                <b> : {props.data.next_level}</b>
                            </div>
                            <div className={`${style.formGroup}`}>
                                <label>{t('Common.amount')}</label>
                                <b> : {props.data.amount}</b>
                            </div>
                            <div className={`${style.formGroup}`}>
                                <label>{t('Common.sendTo')}</label>
                                <b> : {props.data.to_user}</b>
                            </div>
                            <CustomButton variant="primary" onClick={props.donate}>
                                {t('Button.donate')}
                            </CustomButton>
                        </React.Fragment>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default ViewContent
