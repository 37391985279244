import React from 'react';
import {Card,Form} from  'react-bootstrap';
import style from './party.module.scss';
import {useTranslation} from 'react-i18next';
import {DropDownBs} from '../common';
import NewHost from './NewHost';
function StepTwo(props) {
    const {t} = useTranslation();
    return (
        <Card className={`${style.Card}`}>
            <Card.Body className={`${style.CardBody}`}>
                    <legend>
                        <span>{t('Common.stepTwo_SelecttheHost')}</span>
                    </legend>
                    <Form>
                        <Form.Check 
                            type='radio'
                            label={t('Common.chooseAnExistingHost')}
                            name="host"
                            value="old"
                            onChange={props.changeHandler}
                            checked={props.partyContent.host === "old"}
                        />
                        { 
                            props.partyContent.host === "old" &&
                            <DropDownBs
                                options={{
                                    data:props.data.host,
                                    value : 'value',
                                    label : 'code'
                                }}
                                preOption='hostName'
                                DropdownProps={{
                                    name : "oldHost",
                                    onChange : props.changeHandler
                                }}
                            />
                        }
                        <Form.Check 
                            type='radio'
                            label={t('Common.iamtheHost')}
                            name="host"
                            value="iam"
                            onChange={props.changeHandler}
                            checked={props.partyContent.host === "iam"}
                        />
                        <Form.Check 
                            type='radio'
                            label={t('Common.createNewHost')}
                            name="host"
                            value="new"
                            checked={props.partyContent.host === "new"}
                            onChange={props.changeHandler}
                        />
                        {
                            props.partyContent.host === "new" &&
                            <NewHost
                                form={props.partyContent.newHost}
                                changeHandler={props.changeHandler}
                                country={props.partyContent.country}
                                state={props.partyContent.state}
                                keyValue="newHost"
                            />
                        }
                    </Form>
            </Card.Body>
        </Card>
    )
}

export default StepTwo
