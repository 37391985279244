import React, { useEffect, useState } from "react";
import EwalletService from '../../../service/ewallet/Ewallet';
import Table from 'react-bootstrap/Table';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import style from './history.module.scss';



//style
import {Row,Col} from 'react-bootstrap'
  
export  class ComponentToPrint extends React.PureComponent{


  
    constructor(props) {
        super(props);
         this.service = new EwalletService();


       
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
      // const time = current.getHours() + ':' + current.getMinutes();
    
      this.state = {
        earnings:{},
        loading:true,
        date: date
    };
    }
    
          
  
    render(){ 
        return (

            <div className={`${style.printArea}`}>
                
            <Table responsive borderless width="700" height="100">
                <tbody>
                    <tr>
                        <td colSpan="2">
                        <div>{this.state.date}</div><br/>
                            <h3>
                                <b>{this.props.t('Common.orderId')}:
                                    <font color="#7266ba">#{this.props.data.order_id}</font>
                                </b>
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <b>{this.props.t('Common.dateAdded')} &nbsp;</b>
                            {moment(this.props.data.date_added).format('YYYY/MM/DD')}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <b>{this.props.t('Common.shippingMethod')} &nbsp;</b>
                            {this.props.data.shipping_method}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <b>{this.props.t('profile.paymentMethod')} &nbsp;</b>
                            {this.props.data.payment_method}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h2>{this.props.t('Common.paymentAddress')}</h2>
                            <b>{this.props.data.full_name}</b><br/>
                            {this.props.data.payment_address_1} <br/>
                            {this.props.data.payment_city}, {this.props.data.payment_zone} <br/>
                            {this.props.data.payment_country}
                        </td>
                     
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <h2>
                                <b>{this.props.t('Common.orderProducts')} </b>
                            </h2>
                                <hr/>
                                <Table bordered striped hover className={style.bordered}>
                                    <thead>
                                        <tr>
                                            <th>
                                                {this.props.t('profile.product')}
                                            </th>
                                            <th>
                                                {this.props.t('Common.quantity')}
                                            </th>
                                            <th>
                                                {this.props.t('Common.pairValue')}
                                            </th>
                                            <th>
                                                {this.props.t('Common.price')}
                                            </th>
                                            <th>
                                                {this.props.t('Common.totalPairValue')}
                                            </th>
                                            <th>
                                                {this.props.t('Common.total')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.data.products&&
                                            this.props.data.products.map((product,index)=>
                                                <tr key={index}>
                                                    <td>{product.name}</td>
                                                    <td>{product.quantity}</td>
                                                    <td>{product.pair_value}</td>
                                                    <td>
                                                        {this.props.currency.currentCurr} 
                                                        {(parseInt(product.price)*this.props.currency.value).toFixed(this.props.currency.precision)}
                                                    </td>
                                                    <td>
                                                        {parseInt(product.quantity)* parseInt(product.pair_value)}
                                                    </td>
                                                    <td>
                                                        {this.props.currency.currentCurr} 
                                                        {(parseInt(product.total)*this.props.currency.value).toFixed(this.props.currency.precision)}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td colSpan="6">
                                                <hr/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <br/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <table border="0" width="30%" height="100"  align="right" className={style.tableBottom}>
                                <tbody>
                                    {
                                        this.props.data.order_total &&
                                        this.props.data.order_total.map((order,index)=>
                                            <tr key={index}>
                                                <td width="60%">
                                                    <h6>{order.title}</h6>
                                                </td>
                                                <td>
                                                    {this.props.currency.currentCurr}
                                                    {(parseInt(order.value)*this.props.currency.value).toFixed(this.props.currency.precision)}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
        );
   
}

}




const OrderHistoryPrintView = React.forwardRef((props, ref) => {

  const Currency = useSelector(state => state.curr);
  const {t}  = useTranslation();
       const [companyDetails,setCompanyDetails]= useState({})
    return <ComponentToPrint ref={ref} {...props} company={companyDetails} t ={t} />
  
}
)
 export default OrderHistoryPrintView ;

     
    
 



  