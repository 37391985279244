import React from 'react';
import {Card,Form} from  'react-bootstrap';
import style from './party.module.scss';
import {useTranslation} from 'react-i18next';
import NewHost from './NewHost';
function StepFour(props) {
    const {t} = useTranslation()
    return (
        <Card className={`${style.Card}`}>
            <Card.Body className={`${style.CardBody}`}>
                    <legend>
                        <span>{t('Common.stepFour')}</span>
                    </legend>
                    <Form.Check 
                        type='radio'
                        label={t('Common.useTheHostAddress')}
                        name="address"
                        value="host_address"
                        onChange={props.changeHandler}
                        checked={props.partyContent.address === "host_address"}
                    />
                    <Form.Check 
                        type='radio'
                        label={t('Common.useTheOwnerAddress')}
                        name="address"
                        value="user_address"
                        onChange={props.changeHandler}
                        checked={props.partyContent.address === "user_address"}
                    />
                    <Form.Check 
                        type='radio'
                        label={t('Common.createNewAddress')}
                        name="address"
                        value="new_address"
                        onChange={props.changeHandler}
                        checked={props.partyContent.address === "new_address"}
                    />
                    {
                        props.partyContent.address === "new_address"&&
                        <NewHost 
                            form={props.partyContent.newAddress}
                            changeHandler={props.changeHandler}
                            country={props.partyContent.country}
                            state={props.partyContent.state}
                            keyValue="newAddress"
                        />
                    }
            </Card.Body>
        </Card>        
    )
}

export default StepFour
