import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class Payments{
    key =localStorage.getItem('apiKey')
    //upload payment recipt
    upload_reciept(reciept){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'payment/upload_payment_reciept', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header 
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'access-token'  : UserStore.key
                },
                body: reciept  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //get the back accound details 
    async accoundDetails(){
        const response = await fetch(BASE_URL+'api_register/accound_details', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const legStatus = await response.json();
        return legStatus;
    }

    //remove the image 
    async removeImage(username){
        const response = await fetch(BASE_URL+'api_register/remove_reciept', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(username)
        })
        const legStatus = await response.json();
        return legStatus;
    }

    //check the ewallet payment availability
    async ewalletavailability(formData){
        const response = await fetch(`${BASE_URL}payment/check_ewallet_balance`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(formData)
        })
        const legStatus = await response.json();
        return legStatus;
    }

    //check the epin availability
    async epinAvailability(formData){
        const response = await fetch(BASE_URL+'payment/check_epin_validity', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(formData)
        })
        const legStatus = await response.json();
        return legStatus;
    }
    // getPaymentData
    async getPaymentData(type = '') {
        const response = await fetch(`${BASE_URL}payment/payment_methods?type=${type}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
        })
        const res = await response.json();
        return res;
    }
    //check the purchase wallet availability\
    async purchaseWalletAvailable(data) {
        const response = await fetch(`${BASE_URL}payment/check_purchase_wallet_balance`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body : JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
}