import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router';
import BoardContent from '../../components/network/board/BoardContent';
import BoardService from '../../../service/tree/board';
import companyName from '../../../store/mobxStore/companyName';



const BoardView = (props) => {
   const [state, setState]= useState({
        loading: true,
        totalRow: 0,
        boardDetails: [],
        userBoard: []
    })
const [position,setPosition] = useState('')
    const service = new BoardService();

    useEffect(() =>{
        loadData(props.match.params.id);
    },[])

    //load the board details
   const loadData = (id = '') => {
        service.boardview(id).then(res => {
            if (res.status) {
                setState({
                    loading: false,
                    totalRow: res.data.total_rows,
                    boardDetails: res.data.board_details,
                    userBoard: res.data.user_board
                })
            } else {
                setState({
                    loading: false
                })
                if (res.error.code === 1002) {
                    props.history.push('/logout');
                } else if (res.error.code === 1056) {

                } else if (res.error.code === 1057) {
                    props.history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDenied'
                        }
                    })
                }
            }
        })
    }

    //click the board 
   const boardClick = (id,index) => {
        props.history.push(`/BoardView/${id}`)
        loadData(id)
        setPosition(index)
      //  document.getElementById('BoardList').classList.toggle('active')
        }

        const { t } = props;

    return(
        <div>
     { state.loading &&
         <Spinner />
     }     
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.boardView')}</title>
            </Helmet>
            <PageTitle title="boardView" buttonOn={false} />
            <BoardContent
                boardClick={boardClick}
                position = {position}
                {...state}
            />
        </div>
        
    </div>
    )
}

export default withTranslation()((withRouter(BoardView)))



// didnt done with previous state (state update)