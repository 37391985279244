import React from 'react';
import style from './board.module.scss';
import {Row,Col, Card, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
const BoardContent = (props) => {
    const {t} =useTranslation();
    return (  
        <div className={style.container}>
            <Row>
                {
                    props.boardDetails.map((boardItem,index)=>(
                        <Col lg={3} key={index} onClick={()=>props.boardClick(boardItem.board_id,index)} >
                            <div id="right-sidebar" className={`${style.BoardList}` } style={{background : `${index == props.position ? "#8862e0" : "#2196f3"}`}}>
                                <div className={`${style.body}`}>
                                    <div className={`${style.inline}`}>
                                        <div className={`${style.thumbnail}`}>
                                            <span className={`${style.iconBtn}`}>
                                                <i className="fa fa-user-o"></i>
                                            </span>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className={`${style.boardName}`}>
                                        {boardItem.board_name}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))
                }
                <Col md={12}>
                    <Card>
                        <Card.Body className={style.tablePanel}>
                            <Table responsive bordered>
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>{t('Common.dateOfJoining')}</b></th>
                                        <th><b>{t('Common.boardID')}</b></th>
                                        <th><b>{t('Common.boardUsername')}</b></th>
                                        <th><b>{t('Common.boardSplit')}</b></th>
                                        <th><b>{t('Common.boardView')}</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.userBoard.map((board,index)=>
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{board.date_of_joining}</td>
                                                <td>#{board.seriel_no}</td>
                                                <td>{board.user_name}</td>
                                                <td>{board.split_status}</td>
                                                <td>
                                                    <Link to={`/BoardView/${board.table_no}/${board.id}`}>
                                                        <span className={style.boardView}>
                                                            <i className="fa fa-user-o"></i>
                                                        </span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
 
export default BoardContent;