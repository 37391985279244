import React from 'react';
import {PaymentMethods} from '../common'
import {Form} from 'react-bootstrap';
function Payments(props) {
    return (    
        <Form onSubmit={props.submit}>
            <PaymentMethods
            selectedTab={0}
                {...props}
            />
        </Form>
    )
}

export default Payments
