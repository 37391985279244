import React, { useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";

function MissedDonation(props) {
    
    const [state] = useState({
      loading: false,
    });
    //Only assigned "state" in useState destructuring, Need to check if it's okay. 
  
    const { t } = props;
    if (state.loading) {
      return <Spinner />;
    }

    return (
      <div className="h-100">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.missedDonation")}
          </title>
        </Helmet>
        <PageTitle title="missedDonation" buttonOn={false} />
      </div>
    );
  
}

export default withTranslation()(withRouter(MissedDonation));
