import { BASE_URL ,DEFAULT_KEY} from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class lcpService {
  key = localStorage.getItem('apiKey')
 
 async addToLead(formdata){
      const response = await fetch(`${BASE_URL}lcp/addLcp`, {     //url
          method: 'POST',                 //method
          headers : {                     //passing header
            //   'Accept'        : 'application/json',
            //   'Content-Type'  : 'application/json',
              'api-key'       : this.key?this.key:DEFAULT_KEY,
            
          },
          body : formdata
      })
      if(response.status === 204){
          return {
              status : true
          }
      }else{
          const res = await response.json();
          return res;
      }
  }
  async getCountry(){
    const response = await fetch(`${BASE_URL}common/getAllCountry`, {     //url
        method: 'GET',                 //method
        headers : {                     //passing header
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'api-key'       : this.key?this.key:DEFAULT_KEY,
           // 'access-token'  : UserStore.key
        }
    })
    const result = await response.json();
    return result;
}
}