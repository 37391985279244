import React, { useCallback, useEffect, useState } from "react";
import Content from "../../components/lcp/Content";

import { Helmet } from "react-helmet";
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import {useTranslation}from 'react-i18next';

const leadStatus = [
  {
    id: "Ongoing",
  },
  {
    id: "Rejected",
  },
  {
    id: "Accepted",
  },
];

const interest = [
  {
    id: "Very Interested",
  },
  {
    id: "Interested",
  },
  {
    id: "Not That Interested",
  },
];
const InitialForm = {
  first_name: "",
  last_name: "",
  email_id: "",
  skype_id: "",
  mobile_no: "",
  country: "",
  description: "",
  followup_date: "",
  lead_status: "Ongoing",
  status_change_date: "",
  interest_status: "Interested",

  formError: {
    first_name: "",
    last_name: "",
    email_id: "",
    skype_id: "",
    mobile_no: "",
    country: "",
    description: "",
    followup_date: "",
    lead_status: "",
    status_change_date: "",
    interest_status: "",
    id: "",
  },
};

function Home(props) {
    const {t} = useTranslation();
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.viewLead")}
        </title>
      </Helmet>
      {/* <PageTitle title="viewLead" buttonOn={false} /> */}
      <div className="mt-10">
      <Content />
      </div>
    </div>
  );
}

export default Home;
