
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
//components
import PageTitle from '../../components/common/pageTitle';
import {
    Tiles,
    Content,
    ModalForms
} from '../../components/epin';
import Spinner from '../../shared/Spinner';

//style
import style from '../../components/epin/epin.module.scss';

//service
import EpinService from '../../../service/epin/epin';

import companyName from '../../../store/mobxStore/companyName';

const Epin = (props) => {

  const [ state ,setState] = useState({Modal : {
        show: false,
        key: ''
    },
        tilesData       : [],
        loading:true,
        DataTables : {
        EpinList: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1,
            epins: [],
            amounts: []
        },
        PendingList: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1,
        },
        TransferHistory: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1,
        }
    }})


   const [loader,setLoader] = useState(
       {
           search : false,
           reset : false
    })

    const { t } = props;
    const service = new EpinService();



useEffect(() =>{
    loaddata();
},[])

useEffect(() =>{
   
},[state.DataTables.EpinList.data])

 

 
    //Get epin list
   const getEpinList = (
        perPage = state.DataTables.EpinList.perPage,
        inPage = state.DataTables.EpinList.inPage,
        direction = '',
        order = '',
        epin = '',
        amount = '',
        status = ''
    ) => {
        let start = (inPage - 1) * perPage;
        service.EpinList(start, perPage, direction, order, epin, amount, status).then((res) => {

            
           
            if (res.status) {
             
                setLoader({ 
                    search : false,
                    reset : false
                })

                setState(prevState => ({
                    ...prevState,
                    loading:false,
                    DataTables: {
                        ...prevState.DataTables,
                        EpinList: {
                            ...prevState.DataTables.EpinList,
                            totalRows: res.data.count,
                            data: res.data.table_data,
                            pending: false,
                            epins: res.data.epins,
                            amounts: res.data.amounts && res.data.amounts,
                            
                        }
                    }
                }))
            }
        })
    }

    //initial loading the tiles data
    const loaddata = () => {
       
        service.Epin().then((res) => {
            if (res.data) {
               
                setState(prevState =>({
                        ...prevState,
                    tilesData: res.data.epin_tile
                }));
            }
        });
        getEpinList()
    }


//search handler

const  searchHanlder = (perPage, inPage, direction, order, epins, amounts, status) => {
    setLoader({ search : true,
        reset: false })
    filterEpinList(perPage, inPage, direction, order, epins, amounts, status)
}



    //changeepinTable
  const  filterEpinList = (perPage, inPage, direction, order, epins, amounts, status) => {
     

     
        setState(prevState => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                EpinList: {
                    ...prevState.DataTables.EpinList,
                    pending: true,
                    perPage: perPage,
                    inPage: inPage,
                }
            }
        }))
        
        getEpinList(perPage, inPage, direction, order, epins, amounts, status);
    }

    //get the epin pending lsit
   const getPendingList = (
        perPage = state.DataTables.PendingList.perPage,
        inPage = state.DataTables.PendingList.inPage,
        direction = '',
        order = '',
    ) => {
       
        let start = (inPage - 1) * perPage;
        service.PendingEpinList(start, perPage, direction, order).then((res) => {
            if (res.status) {
                
                setState(prevState => ({
                    ...prevState,
                    DataTables: {
                        ...prevState.DataTables,
                        PendingList: {
                            ...prevState.DataTables.PendingList,
                            totalRows: res.data.count,
                            data: res.data.table_data,
                            pending: false
                        }
                    }
                }))
            }
        })
    }

    //filter pending list
  const  fiterPendingList = (perPage, inPage, direction, order) => {
        setState(prevState => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                PendingList: {
                    ...prevState.DataTables.PendingList,
                    pending: true,
                    perPage: perPage,
                    inPage: inPage,
                }
            }
        }))
        getPendingList(perPage, inPage, direction, order);
    }

    //transfer history
    //get the epin pending lsit
   const getHistoryList = (
        perPage = state.DataTables.TransferHistory.perPage,
        inPage = state.DataTables.TransferHistory.inPage,
        direction = '',
        order = '',
    ) => {
        let start = (inPage - 1) * perPage;
        service.transferHistory(start, perPage, direction, order).then((res) => {
            if (res.status) {
                
                setState(prevState => ({
                    ...prevState,
                    DataTables: {
                        ...prevState.DataTables,
                        TransferHistory: {
                            ...prevState.DataTables.TransferHistory,
                            totalRows: res.data.count,
                            data: res.data.table_data,
                            pending: false
                        }
                    }
                }))
            }
        })
    }

    //filter pending list
   const filterHistoryList = (perPage, inPage, direction, order) => {
        setState(prevState => ({
            ...prevState,
            DataTables: {
                ...prevState.DataTables,
                TransferHistory: {
                    ...prevState.DataTables.TransferHistory,
                    pending: true,
                    perPage: perPage,
                    inPage: inPage,
                }
            }
        }))
        getHistoryList(perPage, inPage, direction, order);
    }


    //E-pin Purchase
   const epinpurchase = () => {
        setState(prevState => ({
            ...prevState,
            Modal: {
                ...prevState.Modal,
                show: true,
                key: 'epinPurchase'
            }
        }))
    }

    //epin request
  const  requestEpin = () => {
        setState(prevState => ({
            ...prevState,
            Modal: {
                ...prevState.Modal,
                show: true,
                key: 'requestEpin'
            }
        }))
    }

    //transfer Epin
  const  transferEpin = () => {
        setState(prevState => ({
            ...prevState,
            Modal: {
                ...prevState.Modal,
                show: true,
                key: 'transferEpin'
            }
        }))
    }

    //close modal
   const handleClose = () => {
        setState(prevState => ({
            ...prevState,
            Modal: {
                ...prevState.Modal,
                show: false,
            }
        }))
    }

    //tab change handler
   const tabChangeHandler = key => {
        if (key === 'epinList') {
            getEpinList()
        } else if (key === 'pending_epin') {
            getPendingList();
        } else if (key === 'EpinTransferHistory') {
            getHistoryList();
        }
    }

    //search epin 
   const searchEpin = (e) => {
      
        if(e && e.length>3){
            // service.searchepin(e).then(res => {

            //     if (res.status) {
            //         setState(prevState => ({
            //             ...prevState,
            //             DataTables: {
            //                 ...prevState.DataTables,
            //                 EpinList: {
            //                     ...prevState.DataTables.EpinList,
            //                     epins: res.data
            //                 }
            //             }
            //         }))
            //     } else {
            //         if (res.error.code === 1002) {
            //             props.history.push('/logout');
            //         }
            //     }
            // })

        }
    
    }

    //reset the value i epin list 
  const  resetEpinLsist = () => {
    setLoader({ search : false,
        reset: true })
        filterEpinList(10, 1);
    }

    //model close when success action
  const  successclose = () => {
        handleClose();
        loaddata()
    }

    const ButtonGroup = [
        {
            name: 'epinPurchase',
            click: epinpurchase
        },
        {
            name: 'requestEpin',
            click: requestEpin
        },
        {
            name: 'transferEpin',
            click: transferEpin
        }
    ];

    //change Handler 
    const changeHandler = {
        list: searchHanlder,
        pending: fiterPendingList,
        history: filterHistoryList
    }
   // loaddata();


return (
       
        <div className={`h-100`}>
       
            <Helmet>
                <title> {companyName.companyName} | {t('Sidemenu.epin')}</title>
            </Helmet>
            <PageTitle
                title="epin"
                buttonOn={true}
                group={ButtonGroup}
            />
            {state.loading ?
            <Spinner/>
            :
            <div className={`${style.Maincontainer}`}>
                <Tiles
                    tilesData={state.tilesData}
                />
                <Content
                    tableData={state.DataTables}
                    filterHandler={changeHandler}
                    loader = {loader}
                    tabChangeHandler={tabChangeHandler}
                    search={searchEpin}
                    reset={resetEpinLsist}
                    getEpinList={getEpinList}
                />
                <Modal
                    show={state.Modal.show}
                    onHide={handleClose}
                    dialogClassName={`${style.ModalDialog} FundTransferModal animate__animated ${state.Modal.show ? 'animate__fadeInRight' : 'animate__fadeOutRight'}  `}
                >
                    <Modal.Header
                        closeButton
                        bsPrefix={style.ModalHeader}
                    >
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={style.ModalBodyHeader}>
                            <h3>
                                {t('Button.' + state.Modal.key)}
                            </h3>
                        </div>
                        <ModalForms
                            field={state.Modal.key}
                            successclose={successclose}
                            getPendingList={getPendingList}
                        />
                    </Modal.Body>
                </Modal>
            </div>}
        </div>


)

}
export default withTranslation()(withRouter(Epin))