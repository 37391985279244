import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import { ViewContent } from "../../components/donation";
import DonationService from "../../../service/donation";
import companyName from "../../../store/mobxStore/companyName";

function DonationView (props) {

    const [state, setState] = useState ({
        loading: true,
        data: {},
        alert: {
        show: false,
        varient: "danger",
        message: "",
      },
    });
    
    const service = new DonationService();
  
//   componentDidMount() {
//     this.loadData();
//   }

//   Used use effect as it's functional based

    // useEffect(() => {
    //   loadData();
    // },[]);

     useEffect(() => {
       loadData();
     },);
    //no empty array passed in use effect. Need to check if it's right or not.

  const loadData = () => {
    const { t } = props;
    service.donationView().then((res) => {
      if (res.status) {
        setState({
          data: res.data,
          loading: false,
          message: res.data.top_level && t("validation.youAreOnTopLevel"),
        });
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1025) {
          setState({
            data: {
              error: true,
              message: t("validation.youDontHaveSuficientBalanceToDonate"),
            },
            loading: false,
          });
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        } else if (res.error.code === 1058) {
          setState({
            data: {
              error: true,
              message: t("validation.youDontHaveSuficientReferralsToDonate"),
            },
            loading: false,
          });
        }
      }
    });
  };
  //click the donate button
  const donate = () => {
    const formData = {
      to_user: state.data.to_user,
    };
    service.donate(formData).then((res) => {
      if (res.status) {
        loadData();
      } else {
        setState({
          alert: {
            show: true,
            message: "validation.donationFailed",
            varient: "danger",
          },
        });
      }
    });
  };
  const closeAlert = () => {
    setState({
      alert: {
        show: false,
      },
    });
  };
  

    const { t } = props;
    if (state.loading) {
      return <Spinner />;
    }

    return (
      <div className="h-100">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.givenDonation")}
          </title>
        </Helmet>
        <PageTitle title="givenDonation" buttonOn={false} />
        <ViewContent
          {...state}
          donate={donate}
          closeAlert={closeAlert}
        />
      </div>
    );
  
}

export default withTranslation()(withRouter(DonationView));
