import React from 'react';
import style from './donation.module.scss';
import {useTranslation} from 'react-i18next';
import {Card,Table}from 'react-bootstrap';
import moment from 'moment';
import {useSelector} from 'react-redux';
import Buttons from './Buttons';
function RecievedContent(props) {
    const {t} = useTranslation();
    const currency = useSelector(state=>state.curr);
    //total amount
    const total_amount = () =>{
        let totalPrice = props.data.reduce(function (accumulator, item) {
            return accumulator + (parseInt(item.amount));
          }, 0);
          return (totalPrice * currency.value).toFixed(currency.precision)
    }
    return (
        <div className={style.Maincontainer}>
            {
                props.total_count>0&&
                <Buttons/>
            }
            <Card>
                <Card.Body className={`${style.CardBody} p-0`} id="divcontents">
                    {
                        props.total_count>0?
                        <Table responsive striped bordered className={style.borderNone} >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('Common.donatedUser')}</th>
                                    <th>{t('Common.fromLevel')}</th>
                                    <th>{t('Common.paymentType')}</th>
                                    <th>{t('Common.date')}</th>
                                    <th>{t('Common.amount')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.data.map((item,index)=>
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.from_user_name}</td>
                                            <td>{item.to_levelname}</td>
                                            <td>{item.payment_type}</td>
                                            <td>{moment(item.date).format('yyyy-mm-DD')}</td>
                                            <td>{currency.currentCurr}{(parseInt(item.amount)*currency.value).toFixed(currency.precision)}</td>
                                        </tr>
                                    )
                                }
                                <tr>
                                    <td colSpan="5" className={`text-right`}>{t('Common.totalAmount')} &nbsp;</td>
                                    <td>{currency.currentCurr} {total_amount()}</td>
                                </tr>
                            </tbody>
                        </Table>
                        :
                        <h4 className={`text-center`}>
                            <font>{t('Common.noDataFound')}</font>
                        </h4>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default RecievedContent
