import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import { DownLineContent } from '../../components/tree'




const DownLineMember = (props) => {

    const { t } = props

    return(

        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.downlineMembers')}</title>
            </Helmet>
            <PageTitle
                title="downlineMembers"
                buttonOn={false}
            />
            <DownLineContent />
        </div>
    )
}

export default withTranslation()(DownLineMember)