import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./mail.module.scss";
import "../../../assets/styles/mailbox.scss";
import { AlertBs } from "../common";
import {
  MailSidebar,
  ContentHeader,
  MailList,
  ViewMail,
  ComposeMail,
} from "./";
import styles from "../../components/tree//tree.module.scss";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

function Content(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  return (
    <div className={style.mailboxMainContent}>
      <Row>
        <Col md={2} sm={12} className={style.leftContent}>
          {props.selectedTab && (
            <MailSidebar
              changeTab={props.changeTab}
              selecetedKey={props.selectedTab}
              unreadCount={props.unReadCount}
            />
          )}
        </Col>
        <Col md={10} sm={12} className={style.rightSide}>
          <AlertBs {...props.alert} close={props.close} />
          {
            // (props.selectedTab !=='compose' ||props.selectedTab !=='reply')&&
            !["compose", "reply"].includes(props.selectedTab) && (
              <ContentHeader
                view={props.view}
                back={props.backButtonClick}
                refersh={props.refersh}
              />
            )
          }
          {props.selectedTab === "compose" || props.selectedTab === "reply" ? (
            <ComposeMail
              state={props.content}
              editorState={props.editorState}
              changeHandler={props.changeHandler}
              replyData={props.replyData}
              selectKey={props.selectedTab}
              messagesend={props.messagesend}
              editorChange={props.editorChange}
              composeData={props.composeData}
            />
          ) : (
            [
              !props.view ? (
                <ListGroup
                  className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_InboxList}`
                      : `${style.InboxList}`
                  }`}
                  key="view"
                >
                  {props.mailData && props.mailData.length > 0 ? (
                    props.mailData.map((mailItems, index) => (
                      <MailList
                        key={index}
                        delete={props.deleteMail}
                        view={props.viewMail}
                        index={index}
                        tab={props.selectedTab}
                        {...mailItems}
                      />
                    ))
                  ) : (
                    <ListGroup.Item className={style.noListItems}>
                      <center>
                        {t(
                          "Common." +
                            (props.selectedTab === "inbox"
                              ? "noMailsInbox"
                              : "noMailSend")
                        )}
                      </center>
                    </ListGroup.Item>
                  )}
                  {
                    //  props.mailData && props.mailData?.length==0?
                    //         <ListGroup.Item className={style.noListItems}>
                    //             <center>
                    //                 {
                    //                     t('Common.'+(props.selectedTab ==='inbox'?'noMailsInbox':'noMailSend'))
                    //                 }
                    //             </center>
                    //         </ListGroup.Item>
                    //     :
                    //     props.mailData.map((mailItems,index)=>(
                    //         <MailList
                    //         key={index}
                    //         delete={props.deleteMail}
                    //         view={props.viewMail}
                    //         index={index}
                    //         tab={props.selectedTab}
                    //         {...mailItems}
                    //         />
                    //     ))
                  }
                </ListGroup>
              ) : (
                <ViewMail
                  key="index"
                  replyMessage={props.replyMessage}
                  data={props.messageData}
                  tab={props.selectedTab}
                  reply={props.selectedTab === "inbox" ? true : false}
                />
              ),
            ]
          )}
        </Col>
      </Row>
      <ReactPaginate
        previousLabel={t("<<")}
        nextLabel={t(">>")}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(props.mailCount / 10)}
        marginPagesDisplayed={5}
        pageRangeDisplayed={5}
        onPageChange={props.handlePageClick}
        containerClassName={styles.paginationContainer}
        activeClassName={styles.active}
        previousLinkClassName={props.offset === 0 ? "d-none" : style.Link}
        nextLinkClassName={!props.showNext ? "d-none" : style.Link}
      />
    </div>
  );
}

export default Content;
