import React, { useState,useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation }  from 'react-i18next';
//components
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner'
import CrmGraph from '../../components/crm/CrmGraph';

//service
import CrmService from '../../../service/crm/Crm';
import { useHistory, withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';
function Graph({t}) {
    
    const service = new CrmService()
    const history = useHistory()
    const [state, setState] = useState({
        loading: true,
        data: [],
        country: [],
        modal: {
            show: false,
            header: ''
        }

    })
    useEffect(() => { 
        CrmGraphs()

     },[]);
    const  CrmGraphs = (range) =>{
        service.CrmGraph().then((res)=>{
            setState(prevState => ({
                ...prevState,
                loading : true
            }));

            if(res.status){
                setState(prevState => ({
                    ...prevState,
                    data:res.data,
                    loading : false
                }));

            }else{
                history.push('/logout');
            }
        })
   
    }
    if(state.loading){
        return <Spinner />
    }
    return ( 
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.graph')}</title>
            </Helmet>
            <PageTitle title="graph" buttonOn={false} />
            <CrmGraph
                {...state}
            />
        </div>
        );
}

export default withTranslation()((withRouter(Graph)))