import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import SubscriptionRenewal from "../../components/subscription/SubscriptionRenewal";
import ProfileService from "../../../service/profile";
import { withRouter } from "react-router-dom";
import style from "../../components/package/package.module.scss";
import companyName from "../../../store/mobxStore/companyName";

function Subscription (props) {

  
const [state, setState] = useState({
        product: {
        id: "",
        price: "",
        validity: "",
      },
      selectedTab: 0,
      payment_methods: [],
      selectedPeyment: "",
      alert: {
        show: false,
        message: "",
        varient: "",
      },
      ewalletData: {
        username: "",
        password: "",
      },

      epinData: {
        pinArray: [],
      },
      paypal: {
        orderId: "",
        payeerId: "",
      },
});
    

     
    

    const service = new ProfileService();
    // this.loadDetails();
  
    useEffect(() => {
      loadDetails();
      loadPaymentData();
    },[]);


  // componentDidMount() {
  //   this.loadDetails();
  //   this.loadPaymentData();
  // };





  //load details
  const loadDetails = () => {
     service.subscriptionDetails().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          product: res.data,
        }));
      }
    });
  };

  const loadPaymentData = () => {
     service.getPaymentData("membership_renewal").then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          payment_methods: res.data,
        }));
      }
    });
  };

  // Payment method change event
  const changeTabHandler = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedPeyment: state.payment_methods[tab],
    }));
  };

  // Payment Submit Event
  const submitSubscription = (event) => {
    event.preventDefault();
    let formData = {
      product_id: state.product.id,
      payment_method: state.selectedPeyment.code,
    };
    if (state.selectedPeyment.code === "ewallet") {
      formData.user_name_ewallet = state.ewalletData.username;
      formData.tran_pass_ewallet = state.ewalletData.password;
    } else if (state.selectedPeyment.code === "epin") {
      formData.pin_array = state.epinData.pinArray.length;
      state.epinData.pinArray.forEach((element, index) => {
        formData["epin" + (index + 1)] = element.pin;
      });
    } else if (state.selectedPeyment.code === "paypal") {
      formData.paypal_token = state.paypal.orderId;
      formData.PayerID = state.paypal.payeerId;
      formData.currency = "USD";
    }
     service.subscriptionSubmit(formData).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          alert: {
            show: true,
            message: "validation.success",
            varient: "success",
          },
          selectedTab: 1,
        }));
        window.scrollTo(0, 0);
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        }
      }
    });
  };
 const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  //get the ewallet data
  const getEwalletData = (username, password) => {
    setState((prevState) => ({
      ...prevState,
      ewalletData: {
        username: username,
        password: password,
      },
    }));
  };

  /**
   * Epin Data
   */
  const getEpinData = (pinArray) => {
    if (pinArray.length > 0) {
      setState((prevState) => ({
        ...prevState,
        epinData: {
          pinArray,
        },
      }));
    }
  };

  const paypalData = (data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        paypal: {
          orderId: data.orderID,
          payeerId: data.payerID,
        },
        
      }));
    }
  };
  

    const { t } = props;
    return (
      <div className={`h-100`}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.packageUpgrade")}
          </title>
          <script src="https://www.paypal.com/sdk/js?client-id=AemLOR_HbPPfHpnTATUqTaILfwAmNEhJDAOH4gVJZ5kouTfaobm19XqYYdaDIWQTI6uETPvNs_hrDEYC"></script>
        </Helmet>
        <PageTitle title="subscriptionRenewal" buttonOn={false} />
        <div className={style.MainContainer}>
          <SubscriptionRenewal
            data={state}
            tabChange={changeTabHandler}
            onSubmit={submitSubscription}
            paymentData={state.payment_methods}
            ewallet={getEwalletData}
            epin={getEpinData}
            alert={state.alert}
            closeAlert={closeAlert}
            getPaypalData={paypalData}
          />
        </div>
      </div>
    );
  
}

export default withTranslation()(withRouter(Subscription));


