// let currentLang=localStorage.getItem('i18nextLng');
let language = {
    currentLang : localStorage.getItem('i18nextLng')??'en',
    languages   : []
}

const changeLangReducer = (state =  language , action)=>{
    switch (action.type) {
        case 'CHANGE_LANG':
            return { ...state, currentLang: action.lang };//change language
        case 'ADD_Lang' :
            return {...state,languages : action.lang }
        default:
            return state;
    }
}

export default changeLangReducer;