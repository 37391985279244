import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class DonationService{
    key =localStorage.getItem('apiKey')
    async donationView(){
        const response = await fetch(`${BASE_URL}donation/donation_view`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
    async donate(data){
        const response = await fetch(`${BASE_URL}donation/donate_fund`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body : JSON.stringify( data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
    async recievedDonation(){
        const response = await fetch(`${BASE_URL}donation/recieve_donation_report`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
    async sendDonation(){
        const response = await fetch(`${BASE_URL}donation/sent_donation_report`,{
            method : 'GET',
            headers : {
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        });
        const result = await response.json();
        return result;
    }
}