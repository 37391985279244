import React, {  useState } from 'react'
import style from './network.module.scss';
import {useHistory} from 'react-router-dom';
import {
    SearchContent,
    SponsorContent
    } from './';
import { useSelector } from "react-redux";
function SponsorTreeContent(props) {
  const history = useHistory();
  const username = useSelector(state=>state.ProjectConfig.userName)
  const [searchExpand, setSearchExpand] = useState(false);
  const [state,setState] = useState({
        username : username,
        error : {
            error : false
        },
        searchloader:false,
        resetloader:false

      })
    //search button click
    const searchClick=(e) =>{
        e.preventDefault();
        setSearchExpand(true);
        setState(prev=>({
            ...prev,
            searchloader : true
        })) 
        setTimeout(() => {
            if(state.username){
                props.fileterUser(state.username)
              }
              setState(prev=>({
                ...prev,
                searchloader : false
            })) 
          }, 2000);

    }
    //change handler
    const change = (e) =>{
        const {value} = e.target
        setState(prev=>({
            ...prev,
            username : value
        }))    
    }
    //click the referral members
    const clickDownLine  = () =>{
        history.push('/referralMembers')
    }
    const resetButtonClick = () =>{
        setSearchExpand(true);
        props.resetButtonClick();
        setState(prev=>({
            ...prev,
            resetloader : true
        })) 
        setTimeout(() => {
              setState(prev=>({
                ...prev,
                resetloader : false,
                username : username
            })) 
          }, 2000);

    }
    return (
        <div className={style.MianContent}>
            <SearchContent 
                searchClick={searchClick}
                changeHandler={change}
                buttonClick={clickDownLine}
                buttonLabel="referralMembers"
                reset={resetButtonClick}
                resetloader={state.resetloader}
                searchloader={state.searchloader}
                {...state}
            />
            {
                props.data&&
                <SponsorContent 
                    {...props} searchExpand={searchExpand}  setSearchExpand={setSearchExpand}
                />
            }
        </div>
    )
}

export default SponsorTreeContent
