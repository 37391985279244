import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import TimeLineContent from '../../components/support/TimeLineContent';
import Spinner from '../../shared/Spinner';
import ticketService from '../../../service/ticket/ticket';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';


function TicketTimeLine(props) {

    const service = new ticketService();

    const [state, setState] = useState({
        loader: true,
        ticket_id: props.match.params.id,
        time_lines: [],
        alert: {
            show: false,
            message: '',
            varient: ''
        },
    });

    const getTicketTimeLine = (ticket_id) => {
        setState((prevState) => ({
            ...prevState,
            loader: true
        }));

        service.getTicketTimeLine(ticket_id)
            .then(res => {
                if (res.status) {
                    setState((prevState) => ({
                        ...prevState,
                        time_lines: res.data,
                        loader: false
                    }))
                }
            })
    }


    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            loader: false
        }));

        getTicketTimeLine(state.ticket_id);
    }, []);

    //close alert
    // const closeAlert = () =>{
    //    setState((prevState) => ({ 
    // 			...prevState,
    //         alert : {
    //             show  : false,
    //             varient : '',
    //             message : ''
    //         }
    //     }))
    // }
    // gonna comment this now, as it's not used anywhere.


    const { t } = props;
    if (state.loader) {
        return <Spinner />
    }

    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.timeLine')}</title>
            </Helmet>
            <PageTitle title="timeLine"
                buttonOn={false}
                AddonIcon={true}
                icon="fa fa-bar-chart"
                group={props.ButtonGroup}
            />


            <TimeLineContent
                {...state}
            />
        </div>
    );

}
export default withTranslation()(withRouter(TicketTimeLine)); 