import React, { useEffect, useRef } from 'react'
import style from './network.module.scss';
import {SponsorTreeNode} from './';
import { useState } from 'react';
import GenealogyService from '../../../service/tree/genealogy';
import {useHistory} from 'react-router-dom';
function SponsorContent(props) {
    const myRef = useRef(null);
    const service = new GenealogyService();
    const history = useHistory();
    const [expand,setExpand] = useState({
        show : false,
        data : [],
        level : 1,
        setChange : {}
    });
    if (props.searchExpand) {

        expand.data = []

    }
    useEffect(()=>{
        const element =document.getElementsByClassName(style.SponsorContent)[0].getBoundingClientRect();
       // myRef.current.scrollLeft = element.width+500;
        // window.scrollTo(0, myRef.current.offsetTop) 
    },[])
    //onclick the expant icon 
    const showExpand =(data,showFunction,userId,level,userName)=>{
        props.setSearchExpand(false)
        //close the all expand node
        let {setChange} = expand;
        if(setChange['level'+level]){
            setChange['level'+level][userName] = showFunction
        }else{
            let tempLevel ={
                ['level'+level] : {
                    [userName] : showFunction
                }
            }
            Object.assign(setChange,tempLevel)
        }
        for (const [key] of Object.entries(setChange['level'+level])) {
            setChange['level'+level][key](false)
        }
        let treeData = expand.data;
    
        treeData.length=level
        treeData.push(data);
        showFunction(true);
        setExpand(prev=>({
            ...prev,
            show  :true,
            data : treeData,
        }))
        service.getSponsorTree(userName).then(res=>{
            if(res.status){
                
                treeData.pop();
                treeData.push(res.data.TreeData[0])
                setExpand(prev=>({
                    ...prev,
                    data : treeData,
                }))    
            }else { 
                if(res.error.code ===1002){
                    history.push('/logout')
                }else if(res.error.code ===1043){
                    history.push({
                        pathname: '/sponsorTree',
                        state:{
                            error : true,
                            message : 'invalidusername'
                        }
                    })
                }
            }
        })
    }

    //close the expand button click
    const closeExpand =(level)=>{
        let treeData = expand.data;
        treeData.length=level;
        setExpand(prev=>({
            ...prev,
            data : treeData,
            //show  :false,
        }))
    }
    return (
        <div className={style.SponsorContent} ref={myRef} >
            <div className={style.treeChart}>
                <div className={style.main}>
                    <SponsorTreeNode
                        {...props.data[0]}
                        expand={showExpand}
                        closeExpand={closeExpand}
                        setSearchExpand={props.setSearchExpand}
                        depthLevel={0}
                        toolTipConfig={props.tooltipConfig}
                        callParent={props.parentNode}
                        firstLevel={true}
                        />
                </div>
            </div>
            {
                expand.show   &&  !props.searchExpand && 
                expand.data.map((items,index)=>{
                    return  <div className={style.treeChart} key={index}>
                        <div className={style.main}>
                            < SponsorTreeNode
                                {...items}
                                depthLevel={index+1}
                                expand={showExpand}
                                closeExpand={closeExpand}
                                setSearchExpand={props.setSearchExpand}
                                callParent={props.parentNode}
                                toolTipConfig={props.tooltipConfig}
                                expandLevel={true}
                                setTriggerExpand={props.setTriggerExpand}
                            />
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default SponsorContent
