import React from 'react';
import { Dropdown} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './epin.module.scss';

const epinToggle = React.forwardRef(({ children, onClick }, ref) => (
    
    <span
      className={style.selectedCatgory}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      {children}
      <span className={style.caretIcon}></span>
    </span>
  ));
function DropDown(props) {
    const DropDownData = ['active','blocked','used_expired','deleted'];
    const {t} =  useTranslation();
    return (
        <div className={style.filterSection}>
            <Dropdown className={`${style.filterDropDown}`} onSelect={props.changeHandler}>
                <Dropdown.Toggle as={epinToggle}>
                    { t('Common.'+props.status)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {
                    DropDownData.map((items,index)=>{
                    
                        return <Dropdown.Item key={index} eventKey={items}>{t('Common.'+items)}</Dropdown.Item>
                    })
                }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default DropDown
