import React, { useState,useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import HistoryContent from '../../components/orderHistory/historyContent';
import OrderService from '../../../service/orderHistory/orderHistory';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory, withRouter } from 'react-router';


function OrderHistory({t}) {
    let service = new OrderService()
    let history = useHistory()
    const [state, setState] = useState({
        loading: true,
        data: []
    })

    useEffect(() => { 
        service.getOrderHistory().then(res=>{
            if(res.status){
                setState({
                    loading  :false,
                    data  : res.data.order_details
                })
            }else{
                if(res.error.code === 1002){
                    history.push('/logout');
                }else if(res.error.code === 1057 ){
                    history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message : 'permissionDenied'
                        }
                    });
                }
            }
        }) 


     },[]);
     if(state.loading){
         return <Spinner />
     }
     return (
         <React.Suspense fallback={<Spinner/>}>
             <Helmet>
                 <title>{companyName.companyName} | {t('Sidemenu.orderHistory')}</title>
             </Helmet>
             <PageTitle title="orderHistory" buttonOn={false} />
             <HistoryContent 
                 {...state}
             />
         </React.Suspense>
     )
}
export default withTranslation()(withRouter(OrderHistory))
