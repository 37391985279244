import React from 'react';
import {useTranslation} from 'react-i18next';
import style from './Common.module.scss';
import {useHistory} from 'react-router-dom'
import CustumBtnGroup from './CustumBtnGroup';
function BackButton() {
    const {t} = useTranslation();
    const history = useHistory();
    const backButtonClick = () =>{
        history.goBack()
    }
    return (
        <div className={style.backButton}>
            <CustumBtnGroup
                variant="info"
                BtnProps={{
                    onClick:backButtonClick
                }}
            >
                <i className="fa fa-backward"></i>
                {t('Button.back')}
            </CustumBtnGroup>
        </div>
    )
}

export default BackButton
