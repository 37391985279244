import React,{useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//service 
import {CurrencyDecimalFormat} from '../../helper';
import style from './ewallet.module.scss';
const  StatementTable = React.memo(({ContentData,paginationchange})=> {
    const {t}  = useTranslation();
    const Currency       = useSelector(state => state.curr);
    const columns = [
        {
          name: t('Common.description').toUpperCase(),
          selector:row=> row.description,
          width : "30%",
          cell : (row) => <span dangerouslySetInnerHTML={{__html:row.description}} className={style.description}></span>
        },
        {
          name: t('Common.amount').toUpperCase(),
          selector:row=> row.amount,
          
          cell: (row) => <span className={`${row.classnameamount} ${style.badge}`}> {Currency.currentCurr} {Number(row.amount).toFixed(2)}</span>
        },
        {
          name: t('Common.transactionDate').toUpperCase(),
          selector: row=>row.transaction_date,
        },
        {
          name: t('Common.balance').toUpperCase(),
          selector: row=>row.balance,
          cell: (row) => <span className={`${style.balanceAmount} ${style.badge}`}>{Currency.currentCurr} {Number(row.balance).toFixed(2)}</span>
        }
      ];
        const [state, setState] = useState({data: []});
        const {data} = state;
        useEffect(()=>{
            let Data = ContentData.data&&ContentData.data.map((resData)=>{    
                if(resData.type==="credit"){

                    return {
                        classnameamount : style.credit,
                        amount :resData.amount,
                        balance :  resData.balance,
                        description : resData.description,
                        transaction_date : resData.transaction_date
                    }
                }else if(resData.type==="debit"){
                    return {
                        classnameamount : style.debit,
                        amount :resData.amount,
                        balance : resData.balance,
                        description : resData.description,
                        transaction_date : resData.transaction_date
                    }
                }
                return null
            })
            setState(prev => ({
                ...prev,
                data:  Data
              }));
        },[ContentData,setState]);

        //onchange page 
        const handlePageChange = inPage =>{
            paginationchange(inPage,ContentData.perPage)
        }
        const handlePerRowsChange = (perPage, inPage) =>{
            paginationchange(inPage,perPage)
        }
    return (
        <>

        
        <DataTable
            columns={columns}
            data={data}
            highlightOnHover
            progressPending={ContentData.pending}
            progressComponent={<div>Loading</div>}
            persistTableHead
            noHeader
            pagination
            paginationServer
            paginationTotalRows={ContentData.totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            noDataComponent={t('Common.noDataRecordsToDisplay')}
            responsive
        />
        </>
    )
});

export default StatementTable
