import React from 'react';
import style from './board.module.scss';
import { usePopperTooltip } from 'react-popper-tooltip';
import {ToolTips} from '../'
function BoardNode(props) {
    //get the arrows
    const TopLine = () =>{
        let Lines = [];
        for (let i = 0; i < props.width-1; i++) {
            Lines.push(<React.Fragment key={i}>
                        <td className={`${style.line} ${style.right} ${style.top}`}>&nbsp;</td>
                        <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                    </React.Fragment>) 
        }
        return Lines
    }
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
      } = usePopperTooltip();
    const getChild = () =>{
        let ChildComponents =[];
        if(props.currentdepth<props.depth){
            const chiledLength = props.children?props.children.length:0;
            for (let i = 0; i < (props.width-chiledLength); i++) {
                ChildComponents.push(<td colSpan="2" key={chiledLength+i}>
                    <BoardNode
                        toolTipConfig={props.toolTipConfig}
                        user_name="NA"
                        depth={props.depth}
                        width={props.width}
                        currentdepth={props.currentdepth+1}
                        children={[]}
                /></td>)
                
            }

        }
        return ChildComponents
    }
    return (
        <table cellPadding={0} cellSpacing={0} className={`${style.mainTable}`}>
            <tbody>
                    <tr className={style.rootNode} >
                        <td colSpan={props.width*2}>
                            <div className={style.node}>
                                <span>
                                    <br/>
                                    <span ref={setTriggerRef}>
                                        {props.user_name}
                                    </span>
                                    {visible&&props.user_name!=='NA' && (
                                    <div
                                    ref={setTooltipRef}
                                    {...getTooltipProps({ className: `tooltip-container` })}
                                    >
                                        <div {...getArrowProps({ className: ` ${style.arrow}` })} />
                                            <ToolTips 
                                            config={props.toolTipConfig}
                                                {...props}
                                            />
                                        </div>
                                    )}
                                </span>
                            </div>
                        </td>
                    </tr>
                    {
                        props.currentdepth<props.depth&&
                        <React.Fragment>
                            <tr>
                                <td colSpan={props.width*2}>
                                    <div className={`${style.line} ${style.down}`}></div>
                                </td> 
                            </tr>
                            <tr>
                                <td className={`${style.line} ${style.left}`}>&nbsp;</td>
                                {
                                    TopLine()
                                }
                                <td className={`${style.line} ${style.right}`}>&nbsp;</td>
                            </tr>
                            <tr>
                                    {
                                        props.children&&props.children.map((item,index)=>
                                            <td colSpan="2" key={index}>
                                                <BoardNode
                                                    {...item}
                                                    depth={props.depth}
                                                    width={props.width}
                                                    currentdepth={props.currentdepth+1}
                                                    toolTipConfig={props.toolTipConfig}
                                                    />
                                            </td>
                                            )
                                    }
                                    {
                                        getChild()
                                    }
                                
                            </tr>
                        </React.Fragment>
                    }
            </tbody>
        </table>
   
    )
}

export default BoardNode
