import React, { useEffect } from "react";
import style from "./dashboard.module.scss";
import { useTranslation } from "react-i18next";
import platinum from "../../../assets/images/dashboard/pltinum.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";

const Ranks = React.memo((props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  useEffect(() => {
    if (document.getElementsByClassName("progress-bar")[0] != undefined) {
      document.getElementsByClassName("progress-bar")[0].style.backgroundColor =
        "#1b3275";
    }
  }, []);

  const { t } = useTranslation();
  return (
    <div className={style.dashboard_rank_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_dashboard_pannel_box}`
            : `${style.dashboard_pannel_box}`
        }`}
      >
        <div className={style.dashboard_rank_scrl_sec}>
          {props.rank.current && (
            <div className={style.dashboard_rank_row}>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_rank_row_head}`
                    : `${style.dashboard_rank_row_head}`
                }`}
              >
                {t("Common.currentRank")} -{" "}
                <span>{props.rank.current.name}</span>
              </div>
              {props.rank.current.criteria.map((rank, index) => {
                return (
                  <div key={index}>
                    <div className={style.dashboard_rank}>
                      <div className={style.dashboard_rank_ico}>
                        <img src={platinum} alt="" />
                      </div>
                      <div className={style.dashboard_rank_hd}>
                        {" "}
                        {t("Common." + rank.text)}{" "}
                      </div>
                      <div className={style.dashboard_rank_prg_bar}>
                        <ProgressBar
                          max={100}
                          min={0}
                          className={`${style.progress_bar} ${style.progress}`}
                          now={Math.round(rank.percentage)}
                        />
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                              : `${style.dashboard_rank_prg_bar_cnt}`
                          }`}
                        >
                          {parseInt(rank.percentage)}%
                        </div>
                      </div>
                    </div>
                    <div className={style.dashboard_rank_cnt_sc}>
                      <div className={style.dashboard_rank_box}>
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_box_rnd}`
                              : `${style.dashboard_rank_box_rnd}`
                          }`}>
                          {rank.required}
                        </div>
                        <span>{t("Common.required")}</span>
                      </div>
                      <div className={style.dashboard_rank_box}>
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_box_rnd}`
                              : `${style.dashboard_rank_box_rnd}`
                          }`}>
                          {rank.achieved}
                        </div>
                        <span>{t("Common.achieved")}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {props.rank.next && (
            <div className={style.dashboard_rank_row}>
              <div className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_rank_row_head}`
                    : `${style.dashboard_rank_row_head}`
                }`}>
                {t("Common.nextRank")} - <span>{props.rank.next.name}</span>
              </div>
              {props.rank.next.criteria.map((rank, index) => {
                return (
                  <div key={index}>
                    <div className={style.dashboard_rank}>
                      <div className={style.dashboard_rank_ico}>
                        <img src={platinum} alt="" />
                      </div>
                      <div className={style.dashboard_rank_hd}>
                        {" "}
                        {t("Common." + rank.text)}{" "}
                      </div>
                      <div className={style.dashboard_rank_prg_bar}>
                        <ProgressBar
                          max={100}
                          min={0}
                          className={`${style.progress_bar} ${style.progress}`}
                          now={Math.round(rank.percentage)}
                        />
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                              : `${style.dashboard_rank_prg_bar_cnt}`
                          }`}>
                          {parseInt(rank.percentage)}%
                        </div>
                      </div>
                    </div>
                    <div className={style.dashboard_rank_cnt_sc}>
                      <div className={style.dashboard_rank_box}>
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_box_rnd}`
                              : `${style.dashboard_rank_box_rnd}`
                          }`}>
                          {rank.required}
                        </div>
                        <span>{t("Common.required")}</span>
                      </div>
                      <div className={style.dashboard_rank_box}>
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_box_rnd}`
                              : `${style.dashboard_rank_box_rnd}`
                          }`}>
                          {rank.achieved}
                        </div>
                        <span>{t("Common.achieved")}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
export default Ranks;
